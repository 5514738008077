<template>
  <span>
    <b-modal
      id="exit-intent-d"
      centered
      size="lg"
      hide-header-close
      ok-only
      ok-variant="secondary"
      :ok-title="$t('buttonClose')"
    >
      <template #modal-title>
        <b-img src="@/assets/logo-quer.png"></b-img>
      </template>
      <b-card-group deck>
        <b-card
          align="center"
          class="info"
          :class="useHPChat ? 'hp-chat' : ''"
          :title="$t('title1')"
        >
          <b-card-text>
            <div v-if="useHPChat">
              {{ $t("moreInfo", { context: "practitioner" }) }}
            </div>
            <p v-else-if="liveChatAvailable">
              {{ $t("moreInfo", { context: "liveChat" }) }}
            </p>
            <p v-else>
              {{ $t("moreInfo", { context: "offline" }) }}
            </p>
            <div v-if="useHPChat" class="pb-1">
              <b-container fluid xl="*">
                <b-row no-gutters>
                  <b-col cols="6">
                    <b-img
                      :alt="'Tobias Eisenkolb; ' + $t('practitionerInfo')"
                      src="@/assets/hp.webp"
                      class="hp"
                    />
                  </b-col>
                  <b-col cols="6" class="text-left">
                    <div class="hp-caption">
                      <div class="font-weight-bold">Tobias Eisenkolb</div>
                      <div>{{ $t("practitionerInfo") }}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <b-button
              @click.prevent="openChat()"
              variant="primary"
              :class="!useHPChat ? 'mt-5' : ''"
            >
              <BIconChatDotsFill />
              <template v-if="liveChatAvailable">{{
                $t("buttonChat", { context: "liveChat" })
              }}</template>
              <template v-else-if="$chat.available">{{
                $t("buttonChat", { context: "offline" })
              }}</template>
              <template v-else>{{
                $t("buttonChat", { context: "notAvailable" })
              }}</template>
            </b-button>
          </b-card-text>
        </b-card>

        <b-card align="center" class="newsletter" :title="$t('title2')">
          <b-card-text>
            <b-form
              @submit.stop.prevent="registerNewsletter"
              v-if="!registered"
            >
              <b-container fluid xl="*">
                <b-row no-gutters :class="useHPChat ? 'pt-2' : ''">
                  <b-col cols="12">
                    <b-form-input
                      type="text"
                      required
                      :placeholder="$t('inputFirstname') + '*'"
                      v-model.trim="newsletterData.firstname"
                      name="firstname"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="12">
                    <b-form-input
                      type="email"
                      required
                      :placeholder="$t('inputEMail') + '*'"
                      v-model.trim="newsletterData.email"
                      name="email"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col>
                    <b-form-checkbox
                      required
                      value="accepted"
                      unchecked-value="not_accepted"
                      class="privacy"
                      v-model="newsletterData.privacy"
                      name="privacy"
                    >
                      <i18next :translation="$t('inputTerms')">
                        <template #privacyLink>
                          <b-link
                            target="_blank"
                            tabindex="-1"
                            class="text-link"
                            :href="$t('privacyURL')"
                            >{{ $t("privacy") }}</b-link
                          >
                        </template>
                      </i18next>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row no-gutters :class="useHPChat ? 'pt-2' : ''">
                  <b-col>
                    <div v-if="error" class="error">
                      {{ $t("errorNewsletter") }}
                    </div>
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mt-2"
                      :disabled="running"
                    >
                      <b-spinner v-if="running" small></b-spinner>
                      {{ $t("buttonNewsletter") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
            <div v-if="registered">
              {{ $t("infoNewsletterConfirm") }}
            </div>
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-modal>
    <b-modal
      id="exit-intent-m"
      centered
      hide-header-close
      ok-only
      ok-variant="secondary"
      :ok-title="$t('buttonClose')"
    >
      <template #modal-title>
        <b-img src="@/assets/logo-quer-blue.png"></b-img>
      </template>
      <b-card align="center" class="info">
        <b-card-title class="f-h2">
          {{ $t("title1") }}
        </b-card-title>
        <b-card-text>
          <div v-if="useHPChat">
            {{ $t("moreInfo", { context: "practitioner" }) }}
          </div>
          <p v-else-if="liveChatAvailable">
            {{ $t("moreInfo", { context: "liveChat" }) }}
          </p>
          <p v-else>
            {{ $t("moreInfo", { context: "offline" }) }}
          </p>
          <div v-if="useHPChat" class="pb-2">
            <b-container fluid xl="*">
              <b-row no-gutters>
                <b-col cols="6">
                  <b-img
                    :alt="'Tobias Eisenkolb; ' + $t('practitionerInfo')"
                    src="@/assets/hp.webp"
                    class="hp"
                  />
                </b-col>
                <b-col cols="6" class="text-left">
                  <div class="hp-caption">
                    <div class="font-weight-bold">Tobias Eisenkolb</div>
                    <div>{{ $t("practitionerInfo") }}</div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <b-button @click.prevent="openChat()" variant="primary">
            <BIconChatDotsFill />
            <template v-if="liveChatAvailable">{{
              $t("buttonChat", { context: "liveChat" })
            }}</template>
            <template v-else-if="$chat.available">{{
              $t("buttonChat", { context: "offline" })
            }}</template>
            <template v-else>{{
              $t("buttonChat", { context: "notAvailable" })
            }}</template>
          </b-button>
        </b-card-text>
      </b-card>
      <b-card align="center" class="newsletter">
        <b-card-title class="f-h2">
          {{ $t("title2") }}
        </b-card-title>
        <b-card-text>
          <b-container fluid xl="*">
            <b-form
              @submit.stop.prevent="registerNewsletter"
              v-if="!registered"
            >
              <b-row>
                <b-col cols="12" sm="6">
                  <b-form-input
                    type="text"
                    required
                    :placeholder="$t('inputFirstname') + '*'"
                    v-model.trim="newsletterData.firstname"
                    name="firstname"
                  ></b-form-input>
                </b-col>
                <b-col cols="12" sm="6">
                  <b-form-input
                    type="email"
                    required
                    :placeholder="$t('inputEMail') + '*'"
                    v-model.trim="newsletterData.email"
                    name="email"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox
                    required
                    value="accepted"
                    unchecked-value="not_accepted"
                    class="privacy"
                    v-model="newsletterData.privacy"
                    name="privacy"
                  >
                    <i18next :translation="$t('inputTerms')">
                      <template #privacyLink>
                        <b-link
                          target="_blank"
                          tabindex="-1"
                          class="text-link"
                          :href="$t('privacyURL')"
                          >{{ $t("privacy") }}</b-link
                        >
                      </template>
                    </i18next>
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div v-if="error" class="error">
                    {{ $t("errorNewsletter") }}
                  </div>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2"
                    :disabled="running"
                  >
                    <b-spinner v-if="running" small></b-spinner>
                    {{ $t("buttonNewsletter") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-row v-if="registered">
              <b-col>
                {{ $t("infoNewsletterConfirm") }}
              </b-col>
            </b-row>
          </b-container>
        </b-card-text>
      </b-card>
    </b-modal>
  </span>
</template>

<script>
import { BIconChatDotsFill } from "bootstrap-vue";

export default {
  name: "ModalExitIntent",
  components: {
    BIconChatDotsFill,
  },
  i18nOptions: { keyPrefix: "exitIntent" },
  data: function () {
    return {
      newsletterData: {
        email: null,
        firstname: null,
        privacy: null,
        source: "Exit-intent",
      },
      running: false,
      error: false,
      registered: false,
      useHPChat: true,
    };
  },
  mounted() {
    if (this.$zmode == "if-p") {
      this.useHPChat = false;
    }
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "exit-intent-m") {
        this.$EventBus.$emit("hideChatWidget", true);
      }
    });
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "exit-intent-m") {
        this.$EventBus.$emit("showChatWidget", true);
      }
    });
  },
  computed: {
    liveChatAvailable: function () {
      const date = new Date();
      const hour = date.getHours();
      const day = date.getDay();
      return (
        this.$chat.available && day >= 1 && day <= 5 && hour >= 9 && hour < 17
      );
    },
  },
  methods: {
    registerNewsletter: function () {
      this.running = true;
      this.error = false;
      this.registered = false;
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.newsletterData),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.running = false;
          if (result.success) {
            this.registered = true;
          } else {
            this.error = true;
          }
        });
      this.$EventBus.$emit("gtag", {
        event: "exit-intent",
        schritt: "register-newsletter",
        teilschritt: this.eventInfo(),
      });
    },
    openChat: function () {
      if (this.$chat.available) {
        this.$EventBus.$emit("openChat");
      } else {
        window.open("https://calendly.com/tobias-58/30min", "_blank");
      }
      this.$bvModal.hide("exit-intent-d");
      this.$bvModal.hide("exit-intent-m");
      this.$EventBus.$emit("gtag", {
        event: "exit-intent",
        schritt: this.useHPChat ? "open-chat-hp" : "open-chat",
        teilschritt: this.eventInfo(),
      });
    },
    eventInfo: function () {
      if (this.$isMobile) {
        return "mobile";
      } else {
        return "desktop";
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 991px) {
  #exit-intent-d .card-deck {
    display: unset;
    margin-left: unset;
    margin-right: unset;
  }
  #exit-intent-d .card-deck .card {
    margin-bottom: 15px;
  }
}

#exit-intent-d .modal-title,
#exit-intent-m .modal-title {
  text-align: center;
  width: 100%;
}
#exit-intent-d .modal-title img {
  height: 80px;
}
#exit-intent-m .modal-title img {
  height: 60px;
}
#exit-intent-d .info,
#exit-intent-m .info {
  background-color: var(--zimply-brown);
  color: var(--zimply-black);
  border-radius: 10px;
}
#exit-intent-d .info .card-text {
  margin-top: 42px;
}
#exit-intent-d .info.hp-chat .card-text {
  margin-top: 0;
}
#exit-intent-d .newsletter,
#exit-intent-m .newsletter {
  background-color: var(--zimply-beige);
  border-radius: 10px;
}
#exit-intent-d .container-fluid {
  padding: 0;
}
#exit-intent-d .btn {
  text-align: left;
}
#exit-intent-d .privacy,
#exit-intent-m .privacy {
  margin-top: 2px;
  text-align: left;
}
#exit-intent-d .b-icon {
  margin-right: 2px;
}

#exit-intent-m .modal-body {
  padding: 0;
}
#exit-intent-m .card {
  border-radius: unset;
}

img.hp {
  height: 150px;
}
.hp-caption {
  position: absolute;
  bottom: 0px;
}
</style>
