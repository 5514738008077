<template>
  <div class="mixture-wrapper">
    <div class="header banner">
      <h1>
        <template v-if="product.spray.type == 'Spray'">
          <span>{{ $t("titlePrefix") }} </span>
          <span>
            {{ recipe.mixture_num }}
          </span>
          <span v-if="hasInput"> {{ $t("titleAppendix") }}</span>
        </template>
        <template v-else-if="product.spray.details.intro">
          {{ $t("titlePrefixProduct") }}{{ product.spray.details.name }}
        </template>
        <template v-else>
          <div>
            {{ product.spray.num }}
          </div>
        </template>
      </h1>
    </div>
    <b-container fluid="xxl" class="mixture-content-wrapper f-p1 my-5">
      <b-row v-if="!recipe.mixture_num">
        <div class="text-center mb-5 px-4 missing-mixture f-p1">
          <p>{{ $t("errorMissingMixture_1") }}</p>
          <p>{{ $t("errorMissingMixture_2") }}</p>
          <p>{{ $t("errorMissingMixture_3") }}</p>
          <b-button @click.prevent="go_start()" variant="green">
            {{ $t("errorMissingMixture_Button") }}
          </b-button>
        </div>
      </b-row>
      <b-row v-if="recipe.mixture_num">
        <b-col cols="12" md="5" class="text-center px-4 mb-4">
          <div class="img-container">
            <div class="position-absolute btn-share">
              <b-button @click="showSendMixture()" href="#" variant="link">
                <BIconBoxArrowUp />
              </b-button>
            </div>
            <div
              v-if="
                product.spray.details.images &&
                product.spray.details.images.length > 0
              "
              class="pl-4 pr-4"
            >
              <b-aspect aspect="1:1">
                <b-carousel controls v-model="active_img">
                  <b-carousel-slide
                    v-for="(img, i) in product.spray.details.images"
                    :key="i"
                    :img-src="img.includes('|') ? img.split('|')[1] : img"
                  >
                    <div
                      class="w-100 h-100"
                      @click="
                        img.includes('|')
                          ? $bvModal.show('video-modal-' + i)
                          : (modal_img = i)
                      "
                    />
                  </b-carousel-slide>
                </b-carousel>
              </b-aspect>
              <template v-for="(img, i) in product.spray.details.images">
                <b-modal
                  v-if="img.includes('|')"
                  :key="i"
                  :id="'video-modal-' + i"
                  centered
                  size="xl"
                  hide-footer
                  hide-header
                >
                  <b-embed
                    type="iframe"
                    aspect="16by9"
                    :src="img.split('|')[0]"
                    allowfullscreen
                  ></b-embed>
                </b-modal>
                <div
                  v-else
                  :key="i"
                  class="img-modal"
                  :class="modal_img == i ? 'active' : ''"
                  @click="modal_img = null"
                >
                  <div class="img-modal-lightbox">
                    <b-img :src="img" />
                  </div>
                  <div class="modal-backdrop" />
                </div>
              </template>
              <b-container fluid class="p-2">
                <b-row>
                  <b-col
                    v-for="(img, i) in product.spray.details.images"
                    :key="i"
                    class="pl-1 pr-1"
                  >
                    <b-aspect aspect="1:1">
                      <b-img
                        fluid
                        thumbnail
                        :src="img.includes('|') ? img.split('|')[1] : img"
                        :class="active_img == i ? 'active' : ''"
                        @click="active_img = i"
                      ></b-img>
                    </b-aspect>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <span v-else class="mixture-bottle-wrapper">
              <b-img
                src="@/assets/spray-empty.png"
                class="mixture-bottle"
              ></b-img>
              <div
                :class="
                  product.spray.type == 'Spray'
                    ? 'mixture-bottle-product-spray-wrapper'
                    : 'mixture-bottle-product-std-wrapper'
                "
              >
                <img class="mixture-bottle-product" :src="productNameImg" />
              </div>
              <div
                v-if="product.spray.type == 'Spray'"
                class="mixture-bottle-name-wrapper"
              >
                <img class="mixture-bottle-name" :src="firstnameImg" />
              </div>
            </span>
          </div>
          <b-row
            class="text-left p-2 mx-0 my-2 border-bottom border-beige d-none d-md-block"
          >
            <span class="h4 title mb-0"
              >{{ this.formatNumber(this.$znc_stats.ts_stars)
              }}<span class="h5 mb-0">/5</span></span
            >
            <span class="nps-stars">
              <BIconStarFill scale="1.5" />
              <BIconStarFill scale="1.5" />
              <BIconStarFill scale="1.5" />
              <BIconStarFill scale="1.5" />
              <BIconStarHalf scale="1.5" />
            </span>
          </b-row>
          <b-row class="text-left p-2 mx-0 my-3 d-none d-md-block">
            <b-button
              href="https://www.trustedshops.de/bewertung/info_XBE7E097579748F9F559462E7D0DF5217.html"
              target="_blank"
              variant="light"
              class="f-p1 px-5 text-uppercase"
            >
              {{ $t("buttonShowAllReviews") }}
            </b-button>
          </b-row>
        </b-col>
        <b-col cols="12" md="7" class="px-4">
          <b-row>
            <b-col col>
              <h2 v-if="hasInput">
                <i18next
                  :translation="$t('subTitle', { context: 'withInput' })"
                >
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </h2>
              <h2 v-else-if="product.spray.type == 'Spray'">
                {{ $t("subTitle", { context: "spray" }) }}
              </h2>
              <h2 v-else-if="product.spray.details.intro">
                {{ product.spray.details.name }}
              </h2>
              <h2 v-else>{{ $t("subTitle", { context: "else" }) }}</h2>
              <template v-if="product.spray.details.intro">
                <div class="f-p4 mb-1">
                  {{ $t("productNumber") }} {{ product.spray.num }}
                </div>
                <div class="mb-1">
                  {{ product.spray.details.intro }}
                </div>
                <div
                  v-html="product.spray.details.benefits"
                  class="benefits fp-2"
                />
              </template>
              <div v-else class="benefits fp-2">
                <ul>
                  <li>{{ $t("benefits_1") }}</li>
                  <li>{{ $t("benefits_2") }}</li>
                  <li>
                    <i18next :translation="$t('benefits_3')">
                      <template #dosage>
                        {{ recipe.mixture_dosage }}
                      </template>
                    </i18next>
                  </li>
                  <li>{{ $t("benefits_4") }}</li>
                  <li>
                    <i18next :translation="$t('benefits_5')">
                      <template #footNote>
                        <sup>1)</sup>
                      </template>
                    </i18next>
                  </li>
                  <li>
                    <i18next :translation="$t('benefits_6')">
                      <template #footNote>
                        <sup>2)</sup>
                      </template>
                    </i18next>
                  </li>
                  <li v-if="product.spray.type == 'Spray'">
                    {{ $t("benefits_7") }}
                  </li>
                  <li>{{ $t("benefits_8") }}</li>
                </ul>
              </div>
            </b-col>
          </b-row>
          <b-row class="py-2">
            <b-col cols="12" class="price">{{
              formatCurrency(product.spray.price)
            }}</b-col>
            <b-col cols="12" class="f-p4">
              {{ $t("taxInfo") }}, {{ sprayBasePrice }},
              {{
                $t("deliveryTime", {
                  count: product.spray.details.deliverytime,
                })
              }},
              {{ $t("deliveryCosts") }}
            </b-col>
          </b-row>
          <b-row class="pb-2">
            <b-col cols="12" class="f-p2 d-flex align-items-center">
              {{ $t("quantity") }}&nbsp;
              <b-form-spinbutton
                v-model="quantitySelected"
                :min="1"
                :max="10"
                inline
              />
            </b-col>
          </b-row>
          <b-row class="checkout-buttons">
            <b-col cols="12" lg="6">
              <b-button
                @click="checkoutSprayClick()"
                href="#"
                variant="primary"
                class="f-p1 cart-button"
              >
                {{ $t("buttonSpray") }}
              </b-button>
            </b-col>
            <b-col cols="12" class="payment-alt text-center display-mobile">
              {{ $t("paymentAlt") }}
            </b-col>
            <b-col cols="12" lg="6">
              <div v-if="checkoutState.error" class="error pb-1 f-p3">
                <span v-html="checkoutState.message"></span>
              </div>
              <div v-if="checkoutState.running" class="f-p3">
                <b-spinner small></b-spinner> {{ checkoutState.message }}
              </div>
              <div
                id="paypal-express-pd"
                :class="checkoutState.running ? 'd-none' : ''"
              ></div>
              <div class="f-p4 pt-1">
                <i18next :translation="$t('expressDesc')">
                  <template #termsOfUseLink>
                    <b-link
                      target="_blank"
                      tabindex="-1"
                      class="text-link"
                      :href="$t('termsURL')"
                      >{{ $t("terms") }}</b-link
                    >
                  </template>
                  <template #cancellationLink>
                    <b-link
                      target="_blank"
                      tabindex="-1"
                      class="text-link"
                      :href="$t('cancellationURL')"
                      >{{ $t("cancellation") }}</b-link
                    >
                  </template>
                  <template #privacyLink>
                    <b-link
                      target="_blank"
                      tabindex="-1"
                      class="text-link"
                      :href="$t('privacyURL')"
                      >{{ $t("privacy") }}</b-link
                    >
                  </template>
                </i18next>
              </div>
            </b-col>
          </b-row>
          <b-col cols="12" class="d-none d-md-block">
            <b-row class="pt-5 pb-4">
              <b-col
                no-body
                class="m-0 mb-1 f-p3 rounded-circle plant-circle p-1"
                v-for="(entry, index) in recipe.mixture_content"
                :key="index"
              >
                <b-skeleton-wrapper :loading="loadingEssenceIdx <= index">
                  <template #loading>
                    <b-skeleton-img />
                  </template>
                  <template #default>
                    <b-button
                      @click.prevent="scrollToPlants()"
                      variant="transparent"
                      class="p-0"
                    >
                      <b-img
                        :src="
                          $ZNC_BACKEND_BASE + '/ui/img-essences/' + entry.image
                        "
                      ></b-img>
                    </b-button>
                  </template>
                </b-skeleton-wrapper>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="d-none d-md-block" id="plants">
            <b-row class="align-items-center">
              <b-col cols="2" class="text-center">
                <b-button
                  @click.prevent="scrollToPlants()"
                  variant="transparent"
                >
                  <BIconArrowDown scale="2" />
                </b-button>
              </b-col>
              <b-col cols="10" class="text-md-start text-center">
                <b-button
                  @click.prevent="scrollToPlants()"
                  variant="transparent"
                >
                  <h4>
                    {{ $t("buttonPlants") }}
                  </h4>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
    <div class="banner exit-intent-anchor">
      <b-container fluid="xxl" v-if="recipe.mixture_num">
        <b-row class="py-5 text-center justify-content-center">
          <b-col cols="12">
            <h2>{{ $t("plantsTitle") }}</h2>
          </b-col>
          <b-col cols="12">
            <h2 class="fw-normal">
              {{ $t("plantsSubtitle") }}
            </h2>
          </b-col>
          <b-link
            target="_blank"
            v-bind:href="entry.url"
            class="col-10 col-md-5 col-lg-3 f-p3 plant-card p-4 m-3"
            v-for="(entry, index) in recipe.mixture_content"
            :key="index"
            :disabled="!entry.url"
          >
            <b-img
              :src="$ZNC_BACKEND_BASE + '/ui/img-essences/' + entry.image"
              class="mb-4"
            ></b-img>
            <h3 class="m-0" :id="'essence-' + index">
              {{ entry.name_de }}
            </h3>
            <div class="mb-4">&lbrack;{{ entry.name }}&rbrack;</div>
            <div>
              {{ entry.description }}
            </div>
          </b-link>
        </b-row>
      </b-container>
    </div>
    <b-container fluid="xxl" class="mt-5 text-center">
      <b-row class="pt-2">
        <b-col>
          <h2>{{ $t("recipeTitle") }}</h2>
          <div class="my-3">
            {{ $t("recipeDesc") }}
          </div>
          <div>
            <div class="price text-center f-p1">
              {{ formatCurrency(product.mixture.price) }}
            </div>
            <div class="text-center f-p4 pb-2">
              {{ $t("taxInfo") }}
            </div>
            <b-button
              @click="checkoutRecipe()"
              href="#"
              variant="primary"
              class="f-p1 px-5"
            >
              {{ $t("buttonRecipe") }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="f-p4">
        <b-col>
          {{
            $t("deliveryTime", { count: product.mixture.details.deliverytime })
          }},
          {{ $t("deliveryCosts") }}
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid="xxl" class="mt-5">
      <b-row class="pt-2">
        <b-col>
          <div class="footnotes f-p4">
            <b-link v-b-toggle.collapse-footnotes>
              {{ $t("footnotes_Title") }}
            </b-link>
            <b-collapse id="collapse-footnotes">
              <i18next :translation="$t('footnotes_1')">
                <template #infoLink>
                  <b-link
                    target="_blank"
                    tabindex="-1"
                    :href="$t('infoLinkURL')"
                    >{{ $t("infoLink") }}</b-link
                  >
                </template>
              </i18next>
              <br />
              {{ $t("footnotes_2") }}
            </b-collapse>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <ModalQuantitySelection
      @checkout_spray="checkoutSpray"
      @checkout_subscription="showNewSubscription"
      :price="product.spray.price"
    ></ModalQuantitySelection>
    <ModalNewSubscription
      @checkout_subscription="checkoutSpraySubscription"
      :product="product.spray"
      :firstname="firstname"
      :ok_title="$t('buttonSubscription')"
    />
    <ModalExitIntent></ModalExitIntent>
    <ModalSendMixture
      v-if="recipe"
      :mixture_num="recipe.mixture_num"
      :firstname="firstname"
      :partner="$order_info.partner"
    ></ModalSendMixture>
  </div>
</template>

<script>
import {
  BIconArrowDown,
  BIconBoxArrowUp,
  BIconStarFill,
  BIconStarHalf,
} from "bootstrap-vue";

import ModalExitIntent from "@/components/ModalExitIntent.vue";
import ModalQuantitySelection from "@/components/ModalQuantitySelection.vue";
import ModalNewSubscription from "@/components/ModalNewSubscription.vue";
import ModalSendMixture from "@/components/ModalSendMixture.vue";

export default {
  name: "ProductDetail",
  components: {
    BIconArrowDown,
    BIconBoxArrowUp,
    BIconStarFill,
    BIconStarHalf,
    ModalExitIntent,
    ModalQuantitySelection,
    ModalNewSubscription,
    ModalSendMixture,
  },
  props: ["recipe", "product", "firstname", "branch", "hasInput"],
  i18nOptions: { keyPrefix: "productDetail" },
  metaInfo: function () {
    return {
      title:
        this.product.spray.type == "Spray"
          ? this.recipe.mixture_num
          : this.product.spray.details.intro
          ? this.product.spray.details.name
          : this.product.spray.num,
    };
  },
  data: function () {
    return {
      loadingEssenceIdx: 0,
      exitIntentAnchorWasVisible: false,
      exitIntentAnchorVisibleTime: null,
      exitIntentAlreadyShown: false,
      exitIntentWaitSec: 5,
      productNameImg: null,
      firstnameImg: null,
      quantitySelected: 1,
      quantityMode: "quantity-selection",
      active_img: 0,
      modal_img: null,
      checkoutState: {
        error: false,
        message: null,
        running: false,
      },
      checkoutData: {
        paymentInfo: {
          provider: "PayPal",
          method: "paypal-express-p",
        },
      },
    };
  },
  mounted() {
    this.$EventBus.$emit("execute_abtest", "Quantity_Selection", [
      {
        name: "original",
        activate: () => {},
      },
      {
        name: "Upgrade",
        activate: () => {
          this.quantityMode = "quantity-upgrade";
        },
      },
      {
        name: "CartOnly",
        activate: () => {
          this.quantityMode = "cart-only";
        },
      },
    ]);

    if (this.product.spray.type == "Spray") {
      let canvas = document.createElement("canvas");
      canvas.width = 100;
      canvas.height = 25;
      let ctx = canvas.getContext("2d");
      ctx.font = "normal 700 20px Unknown, sans-serif";
      ctx.textAlign = "center";
      ctx.fillText(this.recipe.mixture_num, 100 / 2, 20, 100);
      this.productNameImg = canvas.toDataURL("image/png");

      canvas = document.createElement("canvas");
      canvas.width = 100;
      canvas.height = 25;
      ctx = canvas.getContext("2d");
      ctx.font = "normal 700 20px Unknown, sans-serif";
      ctx.textAlign = "center";
      if (this.firstname) {
        ctx.fillText(this.firstname, 100 / 2, 20, 100);
      } else if (this.recipe.scope) {
        ctx.fillText(this.recipe.scope, 100 / 2, 20, 100);
      }
      this.firstnameImg = canvas.toDataURL("image/png");
    } else {
      let canvas = document.createElement("canvas");
      canvas.width = 100;
      canvas.height = 75;
      let ctx = canvas.getContext("2d");
      ctx.font = "normal 700 20px Unknown, sans-serif";
      ctx.textAlign = "center";
      let names = this.product.spray.num.split(" ");
      if (names.length > 2) {
        ctx.fillText(names[0], 100 / 2, 20, 100);
        ctx.fillText(names[1], 100 / 2, 40, 100);
        ctx.fillText(names[2], 100 / 2, 60, 100);
      } else if (names.length > 1) {
        ctx.fillText(names[0], 100 / 2, 20, 100);
        ctx.fillText(names[1], 100 / 2, 40, 100);
      } else {
        ctx.fillText(this.product.spray.num, 100 / 2, 20, 100);
      }
      this.productNameImg = canvas.toDataURL("image/png");
    }

    this.loadingEssenceIdx = 0;
    let last_time = 300;
    this.recipe.mixture_content.forEach(() => {
      let time = this.randomNumber(last_time, last_time + 300);
      last_time = time;
      setTimeout(() => {
        this.loadingEssenceIdx++;
      }, time);
    });

    setTimeout(() => {
      document.documentElement.addEventListener(
        "mouseleave",
        this.exitIntentEventListener
      );
    }, 5000);

    if (this.$isMobile) {
      let observerIn = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting === true) {
            this.exitIntentAnchorWasVisible = true;
            this.exitIntentAnchorVisibleTime = new Date().getTime();
          }
        },
        { threshold: [0.1] }
      );
      observerIn.observe(document.querySelector(".exit-intent-anchor"));

      let observerOut = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting === false) {
            if (
              this.exitIntentAnchorWasVisible &&
              this.exitIntentAnchorVisibleTime <
                new Date().getTime() - this.exitIntentWaitSec * 1000
            ) {
              setTimeout(() => {
                this.showExitIntentPopup();
              }, 600);
            } else {
              this.exitIntentAnchorWasVisible = false;
            }
          }
        },
        { threshold: [0.1] }
      );
      observerOut.observe(document.querySelector(".exit-intent-anchor"));
    }

    document.documentElement.addEventListener("keydown", this.escEventListener);
    this.loadPayPal().then(() => {
      this.enablePayPalCheckout();
    });
  },
  destroyed() {
    document.documentElement.removeEventListener(
      "mouseleave",
      this.exitIntentEventListener
    );
    document.documentElement.removeEventListener(
      "keydown",
      this.escEventListener
    );
  },
  computed: {
    sprayBasePrice: function () {
      return this.calcBasePrice(this.product.spray);
    },
  },
  methods: {
    randomNumber: function (min, max) {
      let range = max - min;
      let rand = Math.floor(Math.random() * Math.floor(range));
      return rand + min;
    },
    loadPayPal: function () {
      return this._loadPayPal(false);
    },
    enablePayPalCheckout: async function () {
      /*global paypal */
      paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "gold",
            shape: "rect",
            height: this.$isMobile ? 36 : 43,
            label: "buynow",
          },
          onClick: async (data, actions) => {
            this.buildCheckoutData();
            this.setCheckoutStateRunning();
            if (this.checkoutState.error) {
              return actions.reject();
            }
            this.checkoutState.message = this.$t("messageConfirmPayPal");
            return actions.resolve();
          },
          createOrder: () => {
            return this.preparePayment();
          },
          onError: () => {
            this.setCheckoutStateError(this.$t("errorPaymentExecution"));
          },
          onCancel: () => {
            this.resetCheckoutState();
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              this.checkoutState.message = this.$t("messageProcessing");
              try {
                this.checkoutData.paymentInfo.orderID = details.id;
                this.checkoutData.paymentInfo.id =
                  details.purchase_units[0].payments.captures[0].id;
              } catch (e) {
                // ignore
              }
              this.finishCheckout();
            });
          },
        })
        .render("#paypal-express-pd");
    },
    preparePayment: function () {
      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/prepare-payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.checkoutData),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              if (result.orderId) {
                this.checkoutData.orderId = result.orderId;
              }
              resolve(result.id);
            } else {
              resolve(false);
            }
          });
      });
    },
    finishCheckout: function () {
      this._finishCheckout(this.checkoutData).then((result) => {
        if (result) {
          this.resetCheckoutState();
          this.$router.push("/thankyou");
        } else {
          this.setCheckoutStateError(this.$t("errorStore"));
        }
      });
    },
    buildCheckoutData: function () {
      let product = this.product.spray;
      let discountp = this.getQuantityDiscount(this.quantitySelected);
      let price = this.round(product.price - (product.price * discountp) / 100);
      let brutto = price * this.quantitySelected;
      this.checkoutData.cart = [
        {
          product: product,
          id: product.num,
          type: product.type,
          name: product.details.name,
          firstname: this.firstname,
          deliverytime: product.details.deliverytime,
          count: this.quantitySelected,
          price: price,
          sum: brutto,
          code: product.code,
          branch: product.indication,
          mixture: product.mixture,
          discountp: discountp,
        },
      ];
      this.checkoutData.cartSummary = {
        sum: this.round(brutto),
        netto: this.round(brutto / 1.19),
        tax: this.round(brutto - brutto / 1.19),
      };
      if (!this.checkoutData.uuid) {
        this.checkoutData.uuid = this.generateUuid();
      }
      this.checkoutData.partner = this.$order_info.partner;
      this.checkoutData.lang = this.$i18next.resolvedLanguage;
      return this.checkoutData;
    },
    resetCheckoutState: function () {
      this.checkoutState.running = false;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateRunning: function () {
      this.checkoutState.running = true;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateError: function (message) {
      this.checkoutState.running = false;
      this.checkoutState.error = true;
      this.checkoutState.message = message;
    },
    checkoutSprayClick: function () {
      if (this.quantitySelected > 1) {
        this.quantityMode = "cart-only";
        this.checkoutSpray(this.quantitySelected);
      } else {
        if (this.quantityMode == "cart-only") {
          this.checkoutSpray(1);
        } else if (this.quantityMode == "quantity-upgrade") {
          this.checkoutSpray(1);
        } else {
          this.showQuantitySelection();
        }
      }
    },
    showQuantitySelection: function () {
      this.$bvModal.show("modal-quantityselection");
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: this.recipe.branch,
        schritt: "mengenwahl",
      });
    },
    showNewSubscription: function () {
      this.$bvModal.hide("modal-quantityselection");
      this.$bvModal.show("modal-new-subscription");
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: this.recipe.branch,
        schritt: "abonnement",
      });
    },
    showSendMixture: function () {
      this.$bvModal.show("modal-sendmixture");
    },
    exitIntentEventListener: function (e) {
      if (e.clientY <= 20) {
        this.showExitIntentPopup();
      }
    },
    showExitIntentPopup: function () {
      if (!this.exitIntentAlreadyShown) {
        let event_info = "";
        if (this.$isMobile) {
          this.$bvModal.show("exit-intent-m");
          event_info = "mobile";
        } else {
          this.$bvModal.show("exit-intent-d");
          event_info = "desktop";
        }
        this.exitIntentAlreadyShown = true;
        this.$EventBus.$emit("gtag", {
          event: "konfigurator-checkout",
          indikation: this.recipe.branch,
          schritt: "exit-intent",
          teilschritt: event_info,
        });
      }
    },
    escEventListener: function (e) {
      if (e.key === "Escape") {
        this.modal_img = null;
      }
    },
    go_start: function () {
      this.$EventBus.$emit("go_start");
    },
    checkoutSpray: function (quantity) {
      this.checkoutProduct(this.product.spray, quantity);
    },
    checkoutRecipe: function () {
      this.checkoutProduct(this.product.mixture, 1);
    },
    checkoutSpraySubscription: function (quantity, interval) {
      this.checkoutProduct(this.product.spray, quantity, interval);
    },
    checkoutProduct: function (
      product,
      quantity,
      subscription_interval = null
    ) {
      this.$EventBus.$emit(
        "checkout_product",
        product,
        quantity,
        subscription_interval,
        this.firstname
      );
      this.$router.push({
        name: "Checkout",
        params: {
          quantityMode: this.quantityMode,
        },
      });
    },
    calcBasePrice: function (product) {
      if (product.details.volume) {
        return (
          this.formatNumber((product.price / product.details.volume) * 1000) +
          " €/l"
        );
      }
      return null;
    },
    scrollToPlants: function () {
      const plantsElement = document.getElementById("plants");
      plantsElement.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.footnotes {
  text-align: left;
  a {
    color: black;
    text-decoration: underline;
  }
}
.wait-spinner {
  width: 3rem;
  height: 3rem;
}
</style>

<style>
.mixture-wrapper {
  position: relative;
}
.mixture-bottle-wrapper {
  position: relative;
}
.mixture-bottle {
  position: relative;
  height: 20rem;
}
@media only screen and (max-width: 576px) {
  .mixture-bottle {
    height: calc(20rem * 0.9);
  }
}
.mixture-bottle-product-spray-wrapper,
.mixture-bottle-product-std-wrapper,
.mixture-bottle-name-wrapper {
  position: absolute;
  top: 3.9rem;
  left: 2.3rem;
  width: 5.2rem;
  height: 1.5rem;
}
.mixture-bottle-product-std-wrapper {
  height: 4.5rem;
}
.mixture-bottle-name-wrapper {
  top: 5.9rem;
}
@media only screen and (max-width: 576px) {
  .mixture-bottle-product-spray-wrapper,
  .mixture-bottle-product-std-wrapper,
  .mixture-bottle-name-wrapper {
    top: calc(3.9rem * 0.9);
    left: calc(2.3rem * 0.9);
    width: calc(5.2rem * 0.9);
    height: calc(1.5rem * 0.9);
  }
  .mixture-bottle-product-std-wrapper {
    height: calc(3rem * 0.9);
  }
  .mixture-bottle-name-wrapper {
    top: calc(5.9rem * 0.9);
  }
}
.mixture-bottle-product,
.mixture-bottle-name {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}

.img-container .b-aspect > div,
.img-container .carousel-inner {
  display: flex;
  align-items: center;
}
.img-container .carousel-caption div {
  cursor: pointer;
}
.img-container .carousel-control-prev,
.img-container .carousel-control-next {
  z-index: 10;
}
.img-container .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23bbcc4b%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z%27/%3e%3c/svg%3e");
}
.img-container .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23bbcc4b%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z%27/%3e%3c/svg%3e");
}
.img-container .img-modal {
  display: none;
}
.img-container .img-modal.active {
  display: block;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: pointer;
}
.img-container .img-modal.active .img-modal-lightbox {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 10vh 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-container .img-modal.active img {
  max-width: 100%;
  max-height: 100%;
}
.img-container .img-thumbnail {
  cursor: pointer;
}
.img-container .img-thumbnail.active {
  border: 1px solid var(--zimply-black);
}

.mixture-content-wrapper .benefits ul {
  padding-left: 2rem;
  margin-bottom: 0.25rem;
}
.mixture-content-wrapper .benefits li {
  list-style-type: none;
  position: relative;
}
.mixture-content-wrapper .benefits li:before {
  content: "✓";
  position: absolute;
  left: -1.2em;
}
.mixture-content-wrapper .benefits sup {
  top: -0.7em;
  font-size: 0.6rem;
}
@media only screen and (max-width: 576px) {
  .mixture-content-wrapper .benefits sup {
    top: -0.9em;
    font-size: 0.5rem;
  }
}

.price {
  font-weight: 700;
  font-family: var(--zimply-montserrat);
  text-align: left;
}

.missing-mixture {
  padding-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: black;
  max-width: 500px;
}

.plant-circle {
  max-width: 120px;
}
.plant-circle img,
.plant-circle .b-skeleton-img,
.plant-card img {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 200px;
  border-radius: 50%;
  object-fit: cover;
}
.plant-card {
  color: var(--zimply-black);
  border-radius: 10px;
}
.plant-card:hover {
  text-decoration: none;
  color: var(--zimply-black);
}
.fw-normal {
  font-weight: 400 !important;
}
.btn-share {
  top: 0;
  right: 0;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  z-index: 10;
}
.border-beige {
  border-color: var(--zimply-beige) !important;
}

.btn-transparent {
  background-color: none;
}
a.disabled {
  pointer-events: none;
}

.checkout-buttons .cart-button {
  width: 260px;
}
.checkout-buttons #paypal-express-pd {
  width: 260px;
  height: 42.5px;
}
@media only screen and (max-width: 576px) {
  .checkout-buttons .cart-button {
    width: 225px;
  }
  .checkout-buttons #paypal-express-pd {
    width: 225px;
    height: 36.5px;
  }
  .checkout-buttons .payment-alt {
    max-width: calc(225px + 15px + 15px);
  }
}
</style>
