import Vue from "vue";

import i18next from "i18next";
import I18NextVue from "i18next-vue";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

Vue.use(I18NextVue, { i18next });

const options = {
  loadPath: "/business/v1/translation/{{lng}}",
};

export default function (callback) {
  i18next.on("initialized", () => {
    callback();
  });

  i18next
    .use(Backend)
    .use(LanguageDetector)
    .init({
      debug: false,
      fallbackLng: ["de"],
      load: "languageOnly",
      backend: options,
    });
}
