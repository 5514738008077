<template>
  <div class="circle-slider" :class="disabled ? 'disabled' : ''">
    <svg :height="size" :width="size">
      <circle
        id="circle-slider-circle"
        :class="valid == false ? 'invalid' : ''"
        :cx="center"
        :cy="center"
        :r="radius"
      ></circle>
      <path
        id="circle-slider-progress"
        :d="progressPath(center, center, radius, 0, angle)"
      ></path>
      <circle
        id="circle-slider-knob"
        :cx="polarToCartesian(center, center, radius, angle).x"
        :cy="polarToCartesian(center, center, radius, angle).y"
        :r="knobRadius"
      ></circle>
      <text
        id="circle-slider-text"
        x="50%"
        y="50%"
        dominant-baseline="middle"
        class="f-h1"
      >
        <template v-if="value_">{{ value_ }} {{ unit }}</template>
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CircleSlider",
  props: ["value", "min", "max", "unit", "valid", "disabled"],
  data: function () {
    return {
      size: 220,
      is_dragging: false,
      angle: 0,
      value_: this.value,
    };
  },
  computed: {
    center: function () {
      return this.size / 2;
    },
    radius: function () {
      return this.size / 2 - 20;
    },
    knobRadius: function () {
      if (this.is_dragging) {
        return (this.size * 7) / 100;
      }
      return (this.size * 6) / 100;
    },
    angelStepSize: function () {
      return 360 / (this.max - this.min);
    },
  },
  mounted: function () {
    if (!this.disabled) {
      document
        .getElementById("circle-slider-circle")
        .addEventListener("click", this.click, { passive: true });
      document
        .getElementById("circle-slider-progress")
        .addEventListener("click", this.click, { passive: true });
      document
        .getElementById("circle-slider-knob")
        .addEventListener("touchstart", this.touchstart, { passive: true });
      document
        .getElementById("circle-slider-knob")
        .addEventListener("mousedown", this.touchstart, { passive: true });
      document.addEventListener("touchend", this.touchend, { passive: true });
      document.addEventListener("mouseup", this.touchend, { passive: true });
      document.addEventListener("touchmove", this.touchmove, {
        passive: false,
      });
      document.addEventListener("mousemove", this.touchmove, {
        passive: false,
      });
    }
    if (this.value_) {
      this.angle = (this.value_ - this.min) * this.angelStepSize;
    }
  },
  destroyed: function () {
    this.removeEventListener("circle-slider-circle", "click", this.click);
    this.removeEventListener("circle-slider-progress", "click", this.click);
    this.removeEventListener(
      "circle-slider-knob",
      "touchstart",
      this.touchstart
    );
    this.removeEventListener(
      "circle-slider-knob",
      "mousedown",
      this.touchstart
    );
    document.removeEventListener("touchend", this.touchend);
    document.removeEventListener("mouseup", this.touchend);
    document.removeEventListener("touchmove", this.touchmove);
    document.removeEventListener("mousemove", this.touchmove);
  },
  methods: {
    touchstart: function () {
      this.is_dragging = true;
    },
    touchend: function () {
      this.is_dragging = false;
    },
    recalcAngleAndAnswer: function (event) {
      let circle = document.getElementById("circle-slider-circle");

      let touch = void 0;
      if (event.touches) {
        touch = event.touches[0];
      }
      let center_x =
        circle.getBoundingClientRect().width / 2 +
        circle.getBoundingClientRect().left;
      let center_y =
        circle.getBoundingClientRect().height / 2 +
        circle.getBoundingClientRect().top;
      let pos_x = event.clientX || touch.clientX;
      let pos_y = event.clientY || touch.clientY;
      let delta_y = center_y - pos_y;
      let delta_x = center_x - pos_x;

      let angle = Math.atan2(delta_y, delta_x) * (180 / Math.PI); // Calculate Angle between circle center and mouse pos
      angle -= 90;
      if (angle < 0) {
        angle = 360 + angle;
      }
      this.value_ = Math.floor(angle / this.angelStepSize + this.min);
      this.angle = (this.value_ - this.min) * this.angelStepSize;
      this.$emit("input", this.value_);
    },
    touchmove: function (event) {
      if (this.is_dragging) {
        event.preventDefault();
        this.recalcAngleAndAnswer(event);
      }
    },
    click: function (event) {
      this.recalcAngleAndAnswer(event);
    },
    polarToCartesian: function (centerX, centerY, radius, angleInDegrees) {
      let angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    },
    progressPath: function (x, y, radius, startAngle, endAngle) {
      let start = this.polarToCartesian(x, y, radius, endAngle);
      let end = this.polarToCartesian(x, y, radius, startAngle);

      let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      let d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
      ].join(" ");

      return d;
    },
    removeEventListener: function (element, event, method) {
      if (document.getElementById(element)) {
        document.getElementById(element).removeEventListener(event, method);
      }
    },
  },
};
</script>

<style>
.circle-slider svg {
  margin-bottom: 30px;
}
#circle-slider-circle {
  fill: none;
  stroke: var(--zimply-brown);
  stroke-width: 7px;
  cursor: pointer;
  /*filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));*/
}
.circle-slider.disabled #circle-slider-circle {
  cursor: unset;
}
#circle-slider-circle.invalid {
  stroke: var(--invalid);
}
#circle-slider-progress {
  fill: none;
  stroke: var(--zimply-dark-green);
  stroke-width: 10px;
  cursor: pointer;
  /*filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));*/
}
.circle-slider.disabled #circle-slider-progress {
  stroke: var(--disabled-gray);
  cursor: unset;
}
#circle-slider-knob {
  fill: var(--zimply-black);
  filter: drop-shadow(0 0 5px rgba(0, 0, 0));
  cursor: pointer;
}
.circle-slider.disabled #circle-slider-knob {
  fill: var(--disabled-gray);
  cursor: unset;
}
#circle-slider-text {
  text-anchor: middle;
  user-select: none;
}
</style>
