<template>
  <QuestionBase :step="step" class="qstart">
    <h2>
      Herzlich willkommen bei<br />
      ZIMPLY NATURAL!
    </h2>
    <div>Welchen Weg zu Deiner ZIMPLY NATURAL Mischung möchtest Du gehen?</div>
    <b-container fluid class="f-p2">
      <b-row>
        <b-col cols="6">
          <span class="mixture-bottle-wrapper">
            <b-img src="@/assets/spray.png" class="mixture-bottle"></b-img>
            <div class="mixture-bottle-name-wrapper">
              <img class="mixture-bottle-name" :src="firstnameImg" />
            </div>
          </span>
          <p class="text-left">
            In nur 3 Minuten Deine indivuduelle Mischung erstellen. Starte jetzt
            unseren Fragebogen mit 10-15 Fragen zu Dir und Deiner Gesundheit.
          </p>
          <div>
            <b-button
              href="#"
              block
              variant="primary"
              size="lg"
              @click="$emit('next_without')"
              class="position-relative"
            >
              <b-spinner v-if="running" small></b-spinner>
              Jetzt Fragebogen starten!
            </b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <span class="mixture-bottle-wrapper">
            <b-img src="@/assets/spray.png" class="mixture-bottle"></b-img>
          </span>
          <p class="text-left">
            Profitiere von bereits über
            {{ this.$znc_stats.configs }} individuellen
            Konfigurations&shy;vorgängen und bestelle unseren Bestseller:<br />
            Wechsel-12257
          </p>
          <div>
            <b-button
              href="#"
              block
              variant="primary"
              size="lg"
              to="p/Wechsel-12257"
              class="position-relative"
            >
              <b-spinner v-if="running_bestseller" small></b-spinner>
              Bestseller auswählen
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <hr />
    <b-container fluid class="infos f-p2">
      <b-row no-gutters>
        <b-col cols="4" class="pharmacy">
          <b-img
            :src="
              $ZNC_BACKEND_BASE + '/ui/img-pharmacy/' + $pharmacy.id + '.png'
            "
            width="100"
            :alt="
              $pharmacy.contact.salutation +
              ' ' +
              $pharmacy.contact.title +
              ' ' +
              $pharmacy.contact.firstname +
              ' ' +
              $pharmacy.contact.lastname
            "
          ></b-img>
          <div>
            {{ $pharmacy.contact.salutation }}<br />
            {{ $pharmacy.contact.title }}
            {{ $pharmacy.contact.firstname }}
            {{ $pharmacy.contact.lastname }}
          </div>
        </b-col>
        <b-col cols="8">
          <ul class="benefits">
            <li>In nur 3 Minuten konfiguriert</li>
            <li>Direkt bei unserer Partnerapotheke bestellen</li>
            <li>Therapeutisch bewährt</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <div class="banner">
      <div class="nps-stars">
        <BIconStarFill scale="1.5" />
        <BIconStarFill scale="1.5" />
        <BIconStarFill scale="1.5" />
        <BIconStarFill scale="1.5" />
        <BIconStarHalf scale="1.5" />
      </div>
      83% unserer Kund:innen empfehlen uns weiter!
    </div>
    <div class="f-p3">
      Wenn Du mehr zum Hintergrund der jeweiligen Frage wissen möchtest, kannst
      Du jederzeit auf dieses Zeichen
      <BIconInfoCircle /> klicken
    </div>
  </QuestionBase>
</template>

<script>
import { BIconInfoCircle, BIconStarFill, BIconStarHalf } from "bootstrap-vue";
import QuestionBase from "@/components/QuestionBase.vue";

export default {
  name: "QuestionStart",
  components: {
    BIconInfoCircle,
    BIconStarFill,
    BIconStarHalf,
    QuestionBase,
  },
  props: ["step", "running"],
  data: function () {
    return {
      firstnameImg: null,
    };
  },
  mounted() {
    let canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 25;
    let ctx = canvas.getContext("2d");
    ctx.font = "normal 700 20px Unknown, sans-serif";
    ctx.textAlign = "center";
    ctx.fillText("Für DICH", 100 / 2, 20, 100);
    this.firstnameImg = canvas.toDataURL("image/png");
  },
};
</script>

<style lang="scss" scoped>
.qstart {
  .mixture-bottle {
    height: calc(20rem * 0.6);
  }
  @media only screen and (max-width: 576px) {
    .mixture-bottle {
      height: calc(20rem * 0.5);
    }
  }
  .mixture-bottle-name-wrapper {
    top: calc(6.6rem * 0.65);
    left: calc(2.3rem * 0.6);
    width: calc(4.6rem * 0.6);
    height: calc(1.5rem * 0.6);
  }
  @media only screen and (max-width: 576px) {
    .mixture-bottle-name-wrapper {
      top: calc(6.6rem * 0.55);
      left: calc(2.3rem * 0.5);
      width: calc(4.6rem * 0.5);
      height: calc(1.5rem * 0.5);
    }
  }
  .mixture-bottle-name {
    height: calc(1.5rem * 0.6);
  }
  @media only screen and (max-width: 576px) {
    .mixture-bottle-name {
      height: calc(1.5rem * 0.5);
    }
  }
  .mixture-bottle-wrapper:hover .mixture-bottle-name-wrapper {
    top: calc(6.9rem * 0.65);
  }
  @media only screen and (max-width: 576px) {
    .mixture-bottle-wrapper:hover .mixture-bottle-name-wrapper {
      top: calc(6.9rem * 0.55);
    }
  }
  .btn {
    line-height: unset;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 80px;
    .spinner-border {
      left: 5px;
    }
  }
  @media only screen and (max-width: 576px) {
    .btn {
      min-height: 65px;
    }
  }
}
</style>
<style>
.qstart .question-content-wrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.qstart .question-content-wrapper .content {
  padding-top: 0rem;
  max-width: 600px;
}
.qstart .question-content-wrapper .content > div,
.qstart .question-content-wrapper .content > h2 {
  padding-left: 5px;
  padding-right: 5px;
}
.qstart .banner {
  font-style: italic;
  font-weight: bold;
  background-color: var(--blue);
  color: var(--green);
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.qstart .infos {
  padding: 0;
}
.qstart .pharmacy div {
  font-style: italic;
  font-weight: 700;
  font-size: 0.9rem;
}
.qstart ul.benefits {
  padding-left: 2rem;
  padding-top: 0.2rem;
  margin-bottom: 0;
  text-align: left;
}
.qstart .benefits li {
  list-style-type: none;
  position: relative;
}
.qstart .benefits li:before {
  content: "✓";
  position: absolute;
  left: -1.4em;
}
.qstart .btn {
  margin-top: 10px;
}
.nps-stars {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--star-yellow);
}
.nps-stars .b-icon {
  margin-left: 7px;
  margin-right: 7px;
}
</style>
