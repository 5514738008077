<template>
  <div>
    <b-modal
      id="timeout-modal"
      :title="$t('title')"
      ok-only
      :ok-title="$t('buttonRestart')"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @ok="restart()"
    >
      <p>
        {{ $t("desc") }}
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalTimeout",
  i18nOptions: { keyPrefix: "timeout" },
  methods: {
    restart: function () {
      location.reload();
    },
  },
};
</script>
