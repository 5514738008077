<template>
  <b-modal
    id="modal-new-subscription"
    :title="$t('title')"
    title-class="f-h1"
    size="lg"
    hide-footer
  >
    <b-container class="interval-selection" fluid md="*">
      <b-row class="banner display-desktop">
        <b-col>
          <ul class="benefits fp-2 mb-1">
            <li>
              <i18next :translation="$t('benefits_d', { context: '1' })">
                <template #discount>
                  <strong>{{ $t("benefits", { context: "discount" }) }}</strong>
                </template>
              </i18next>
            </li>
            <li>{{ $t("benefits_d", { context: "2" }) }}</li>
            <li>{{ $t("benefits_d", { context: "3" }) }}</li>
            <li>{{ $t("benefits_d", { context: "4" }) }}</li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="banner display-mobile">
        <b-col>
          <ul class="benefits fp-2 mb-1">
            <li>
              <i18next :translation="$t('benefits_m', { context: '1' })">
                <template #discount>
                  <strong>{{ $t("benefits", { context: "discount" }) }}</strong>
                </template>
              </i18next>
            </li>
            <li>{{ $t("benefits_m", { context: "2" }) }}</li>
            <li>{{ $t("benefits_m", { context: "3" }) }}</li>
            <li>{{ $t("benefits_m", { context: "4" }) }}</li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col md lg="6">
          <p>
            {{ $t("desc") }}
          </p>
          <p>
            {{ $t("labelQuantity") }}
            <b-form-spinbutton v-model="quantity" :min="1" :max="10" inline />
          </p>
          <div class="price-old f-p1 display-desktop">
            {{ formatCurrency(product.price) }}
          </div>
          <div class="price-new f-p1 display-desktop">
            {{ formatCurrency(product.price * 0.8) }}
          </div>
          <div class="price-info display-desktop">
            {{ $t("priceDesc") }}
            {{ calcBasePrice(product, round(product.price * 0.8)) }}
          </div>
        </b-col>
        <b-col md lg="6" class="text-center">
          <h3>{{ $t("interval") }}</h3>
          <CircleSlider
            v-model="interval"
            :min="2"
            :max="20"
            :unit="$t('unit')"
          />
        </b-col>
      </b-row>
      <b-row class="pt-2 display-mobile">
        <b-col>
          <div class="price-old f-p1">
            {{ formatCurrency(product.price) }}
          </div>
          <div class="price-new f-p1">
            {{ formatCurrency(product.price * 0.8) }}
          </div>
          <div class="price-info">
            {{ $t("priceDesc") }}
            {{ calcBasePrice(product, round(product.price * 0.8)) }}
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col cols="12" lg="5">
          <b-button
            @click="addSubscriptionToCart()"
            href="#"
            variant="primary"
            class="f-p1 cart-button"
          >
            {{ ok_title }}
          </b-button>
        </b-col>
        <b-col cols="12" lg="2" class="text-center payment-alt">
          {{ $t("paymentAlt") }}
        </b-col>
        <b-col cols="12" lg="5">
          <div class="paypal-wrapper">
            <div
              id="paypal-subscription-button"
              :class="checkoutState.running ? 'd-none' : ''"
            />
            <div v-if="checkoutState.running">
              <b-spinner small></b-spinner> {{ checkoutState.message }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col cols="12" lg="5"> </b-col>
        <b-col cols="12" lg="7">
          <div class="f-p4">
            <i18next :translation="$t('paypalDesc')">
              <template #termsOfUseLink>
                <b-link
                  target="_blank"
                  tabindex="-1"
                  class="text-link"
                  :href="$t('termsURL')"
                  >{{ $t("terms") }}</b-link
                >
              </template>
              <template #cancellationLink>
                <b-link
                  target="_blank"
                  tabindex="-1"
                  class="text-link"
                  :href="$t('cancellationURL')"
                  >{{ $t("cancellation") }}</b-link
                >
              </template>
              <template #privacyLink>
                <b-link
                  target="_blank"
                  tabindex="-1"
                  class="text-link"
                  :href="$t('privacyURL')"
                  >{{ $t("privacy") }}</b-link
                >
              </template>
            </i18next>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="checkoutState.error" class="error pb-1">
      <span v-html="checkoutState.message"></span>
    </div>
  </b-modal>
</template>

<script>
import CircleSlider from "@/components/CircleSlider.vue";

export default {
  name: "ModalNewSubscription",
  props: ["product", "firstname", "ok_title", "init_interval", "init_quantity"],
  components: {
    CircleSlider,
  },
  i18nOptions: { keyPrefix: "subscription" },
  data: function () {
    return {
      interval: 4,
      quantity: 1,
      plan_id: null,
      checkoutState: {
        error: false,
        message: null,
        running: false,
      },
    };
  },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "modal-new-subscription") {
        if (this.init_interval) {
          this.interval = this.init_interval;
        }
        if (this.init_quantity) {
          this.quantity = this.init_quantity;
        }
        this.loadPayPal().then((success) => {
          if (success) {
            this.loadPayPalButton();
          }
        });
      }
    });
  },
  methods: {
    addSubscriptionToCart: function () {
      this.$emit("checkout_subscription", this.quantity, this.interval);
      this.$bvModal.hide("modal-new-subscription");
    },
    loadPayPal: function () {
      return this._loadPayPal(true);
    },
    loadPayPalButton: function () {
      /*global paypal */
      paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "gold",
            shape: "rect",
            height: this.$isMobile ? 36 : 43,
            label: "pay",
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: this.plan_id,
              quantity: this.quantity,
            });
          },
          onClick: async (data, actions) => {
            this.setCheckoutStateRunning();
            await this.retrieveSubscriptionPlanId();
            if (this.checkoutState.error) {
              return actions.reject();
            }
            this.checkoutState.message = this.$t("messageConfirmPayPal");
            return actions.resolve();
          },
          onError: () => {
            this.setCheckoutStateError(this.$t("errorPaymentExecution"));
          },
          onCancel: () => {
            this.resetCheckoutState();
          },
          onApprove: (data) => {
            this.checkoutState.message = this.$t("messageProcessing");
            let paymentInfo = {
              provider: "PayPal",
              method: "paypal-express-s",
              id: data["orderID"],
              ...data,
            };
            this.storeSubscription(paymentInfo);
          },
        })
        .render("#paypal-subscription-button");
    },
    retrieveSubscriptionPlanId: function () {
      return this.prepareSubscription();
    },
    storeSubscription: function (paymentInfo) {
      this.prepareSubscription(paymentInfo).then((result) => {
        if (result.success) {
          let brutto = this.product.price;
          let netto = this.round(this.product.price / 1.19);
          this.$EventBus.$emit("gtag", {
            event: "konfigurator-checkout",
            schritt: "bestellung",
            teilschritt: "paypal-express-s",
            bestellung_nummer: result.orderId,
            bestellung_gesamtbetrag_brutto: brutto,
            bestellung_gesamtbetrag_netto: netto,
            bestellung_gutscheincode: null,
          });
          let products = [
            {
              id: this.product.num,
              price: brutto,
              type: this.product.type,
              branch: this.product.indication,
              count: this.quantity,
              mixture: this.product.mixture,
            },
          ];
          this.$EventBus.$emit("gtag_ecom", {
            event: "checkout",
            ecommerce: {
              currencyCode: "EUR",
              checkout: {
                actionField: {
                  step: 2,
                  option: "paypal",
                },
                products: products,
              },
            },
            cart: products,
          });
          this.$EventBus.$emit("gtag_ecom", {
            event: "purchase",
            ecommerce: {
              currencyCode: "EUR",
              purchase: {
                actionField: {
                  id: result.orderId,
                  revenue: brutto,
                  tax: this.round(brutto - netto),
                  discount: 0,
                },
                products: products,
              },
            },
            cart: products,
          });
          this.$EventBus.$emit("track_goal", 4, brutto);

          this.$order_info.orderId = result.orderId;
          this.$order_info.email = this.$user.email;
          this.$order_info.sum = brutto;
          this.$order_info.estDeliveryDate = result.estDeliveryDate;

          this.resetCheckoutState();
          this.$router.push("/thankyou");
        } else {
          this.setCheckoutStateError(this.t("errorStore"));
        }
      });
    },
    prepareSubscription: function (paymentInfo = null) {
      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/prepare-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product: this.product,
            interval: this.interval,
            quantity: this.quantity,
            customer: this.$user.email,
            mixture_num: this.product.mixture,
            mixture_code: this.product.code,
            mixture_firstname: this.firstname,
            pharmacy_id: this.$pharmacy.id,
            paymentInfo: paymentInfo,
            lang: this.$i18next.resolvedLanguage,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              this.plan_id = result.plan_id;
              resolve(result);
            } else {
              this.checkoutState.error = true;
            }
          });
      });
    },
    resetCheckoutState: function () {
      this.checkoutState.running = false;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateRunning: function () {
      this.checkoutState.running = true;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateError: function (message) {
      this.checkoutState.running = false;
      this.checkoutState.error = true;
      this.checkoutState.message = message;
    },
    calcBasePrice: function (product, price) {
      if (product.details.volume) {
        if (price) {
          return (
            this.formatCurrency((price / product.details.volume) * 1000) + "/l"
          );
        }
        return (
          this.formatCurrency((product.price / product.details.volume) * 1000) +
          "/l"
        );
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
#modal-new-subscription {
  .modal-title {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
  .modal-subtitle {
    text-align: center;
    font-weight: bold;
  }
  ul.benefits {
    padding-left: 1rem;
  }
  .benefits li {
    list-style-type: none;
    position: relative;
  }
  .benefits li:before {
    content: "✓";
    position: absolute;
    left: -1.2em;
  }
  .price-old {
    text-decoration: line-through;
    color: var(--red);
    font-weight: bold;
  }
  .price-new {
    color: var(--zimply-black);
    font-weight: bold;
  }
  .price-info {
    font-size: 0.6rem;
    font-weight: normal;
    color: var(--zimply-black);
  }
  .cart-button {
    width: 260px;
  }
  #paypal-subscription-button {
    width: 260px;
    height: 42.5px;
  }
  @media only screen and (min-width: 576px) {
    .paypal-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      text-align: right;
    }
  }
  @media only screen and (max-width: 576px) {
    .cart-button {
      width: 225px;
    }
    #paypal-subscription-button {
      width: 225px;
      height: 36.5px;
    }
    .payment-alt {
      max-width: calc(225px + 15px + 15px);
    }
  }
}
</style>
