<template>
  <div>
    <SfModal
      id="login"
      :visible="openModal && !$user.loggedIn"
      :title="modalTitle"
      :persistent="true"
      @close="closeModal()"
      class="rounded"
    >
      <transition name="sf-fade" mode="out-in">
        <div
          v-if="currentScreen === SCREEN_LOGIN"
          :key="SCREEN_LOGIN"
          class="modal-content"
        >
          <form class="form" @submit.prevent="login()">
            <div v-if="loginMessage" class="f-p3 font-weight-bold">
              {{ loginMessage }}
            </div>
            <SfInput
              v-model="email"
              @change="checkEMailValidity()"
              :valid="!error.email"
              :errorMessage="error.email"
              name="email"
              :label="$t('inputEMail')"
              class="form__element"
              type="email"
            />
            <SfInput
              v-model="password"
              @change="checkPasswortLoginValidity()"
              :valid="!error.password"
              :errorMessage="error.password"
              name="password"
              :label="$t('inputPassword')"
              type="password"
              class="form__element"
              :has-show-password="true"
            />
            <div v-if="error.login" class="error">
              {{ error.login }}
            </div>
            <SfButton
              type="submit"
              class="sf-button--full-width form__submit btn-green"
              :disabled="loading"
            >
              <SfLoader :loading="loading">
                <div>{{ $t("buttonLogin") }}</div>
              </SfLoader>
            </SfButton>
          </form>
          <b-link
            class="text-link action-button f-p2"
            @click="currentScreen = SCREEN_RESET"
          >
            {{ $t("linkResetPassword") }}
          </b-link>
          <div class="aside">
            <SfHeading
              :title="$t('titleCreateAccount')"
              :level="2"
              class="aside__heading"
            />
            <b-link
              class="text-link f-p2 action-button"
              @click="currentScreen = SCREEN_REGISTER"
            >
              {{ $t("linkCreateAccount") }}
            </b-link>
          </div>
        </div>
        <div
          v-if="currentScreen === SCREEN_REGISTER"
          :key="SCREEN_REGISTER"
          class="modal-content"
        >
          <form class="form" @submit.prevent="createAccount()">
            <SfInput
              v-model="firstname"
              @change="checkFirstnameValidity()"
              :valid="!error.firstname"
              :errorMessage="error.firstname"
              name="firstname"
              :label="$t('inputFirstname')"
              class="form__element"
            />
            <SfInput
              v-model="lastname"
              @change="checkLastnameValidity()"
              :valid="!error.lastname"
              :errorMessage="error.lastname"
              name="lastname"
              :label="$t('inputLastname')"
              class="form__element"
            />
            <SfInput
              v-model="email"
              @change="checkEMailValidity()"
              :valid="!error.email"
              :errorMessage="error.email"
              name="email"
              :label="$t('inputEMail')"
              class="form__element"
              type="email"
            />
            <SfInput
              v-model="password"
              @change="checkPasswortValidity()"
              :valid="!error.password"
              :errorMessage="error.password"
              name="password"
              :label="$t('inputPassword')"
              type="password"
              class="form__element"
              :has-show-password="true"
            />
            <SfInput
              v-model="password2"
              @change="checkPasswort2Validity()"
              :valid="!error.password2"
              :errorMessage="error.password2"
              name="password2"
              :label="$t('inputPassword2')"
              type="password"
              class="form__element"
              :has-show-password="true"
            />
            <div v-if="error.register" class="error">
              {{ error.register }}
            </div>
            <SfButton
              type="submit"
              class="sf-button--full-width form__submit btn-green"
              :disabled="loading"
            >
              <SfLoader :loading="loading">
                <div>{{ $t("buttonCreateAccount") }}</div>
              </SfLoader>
            </SfButton>
          </form>
          <b-link
            class="text-link action-button f-p2"
            data-testid="log-in-account"
            @click="currentScreen = SCREEN_LOGIN"
          >
            {{ $t("linkLogin") }}
          </b-link>
        </div>
        <div
          v-if="currentScreen === SCREEN_REGISTER_THANKYOU"
          :key="SCREEN_REGISTER_THANKYOU"
          class="modal-content"
        >
          <div>
            <p>{{ $t("titleCreateConfirm") }}</p>
            <p>{{ $t("descCreateConfirm") }}</p>
          </div>
        </div>
        <div
          v-if="currentScreen === SCREEN_RESET"
          :key="SCREEN_RESET"
          class="modal-content"
        >
          {{ $t("descReset") }}
          <form class="form" @submit.prevent="resetPassword()">
            <SfInput
              v-model="email"
              @change="checkEMailValidity()"
              :valid="!error.email"
              :errorMessage="error.email"
              name="email"
              :label="$t('inputEMail')"
              class="form__element"
              type="email"
            />
            <div v-if="error.reset" class="error">
              {{ error.reset }}
            </div>
            <SfButton
              type="submit"
              class="sf-button--full-width form__submit btn-green"
              :disabled="loading"
            >
              <SfLoader :loading="loading">
                <div>{{ $t("buttonReset") }}</div>
              </SfLoader>
            </SfButton>
          </form>
        </div>
        <div
          v-if="currentScreen === SCREEN_RESET_THANKYOU"
          :key="SCREEN_RESET_THANKYOU"
          class="modal-content"
        >
          <div>
            <p>
              {{ $t("descResetConfirm") }}
            </p>
          </div>
        </div>
      </transition>
    </SfModal>
    <ModalWebinar />
    <ModalPartnerAmbassador />
  </div>
</template>
<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import {
  required,
  sameAs,
  minLength,
  email,
  and,
  or,
} from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /[a-zA-Z]+/);
const number = helpers.regex("number", /[0-9]+/);
const special = helpers.regex("special", /[!"§$%&/()=?{}[\]*+~#',;.:\-_]+/);
Vue.use(Vuelidate);
import ModalWebinar from "@/components/UserAccount/ModalWebinar.vue";
import ModalPartnerAmbassador from "@/components/UserAccount/ModalPartnerAmbassador.vue";

import {
  SfModal,
  SfInput,
  SfButton,
  SfHeading,
  SfLoader,
} from "@storefront-ui/vue";

export default {
  name: "ModalLogin",
  components: {
    SfModal,
    SfInput,
    SfButton,
    SfHeading,
    SfLoader,
    ModalWebinar,
    ModalPartnerAmbassador,
  },
  i18nOptions: { keyPrefix: "login" },
  data() {
    return {
      openModal: false,
      SCREEN_LOGIN: "login",
      SCREEN_REGISTER: "register",
      SCREEN_REGISTER_THANKYOU: "register-thankyou",
      SCREEN_RESET: "reset",
      SCREEN_RESET_THANKYOU: "reset-thankyou",
      currentScreen: "login",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password2: "",
      error: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        password2: null,
        register: null,
        login: null,
        reset: null,
      },
      loading: false,
      loginMessage: null,
    };
  },
  created: function () {
    this.$EventBus.$on("open_login", this.openLogin);
    this.$EventBus.$on("open_user_registration", this.openUserRegistration);
  },
  destroyed: function () {
    this.$EventBus.$off("open_login", this.openLogin);
    this.$EventBus.$off("open_user_registration", this.openUserRegistration);
  },
  validations: {
    firstname: {
      required,
    },
    lastname: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
      passwordStrong: and(alpha, or(number, special)),
    },
    password2: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  computed: {
    modalTitle() {
      switch (this.currentScreen) {
        case this.SCREEN_LOGIN:
          return "Anmeldung";
        case this.SCREEN_REGISTER:
          return "Registrierung";
        case this.SCREEN_REGISTER_THANKYOU:
          return "Danke für deine Anmeldung";
        case this.SCREEN_RESET:
        case this.SCREEN_RESET_THANKYOU:
          return "Passwort vergessen";
        default:
          return "Kundenkonto";
      }
    },
  },
  watch: {
    currentScreen() {
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.password = "";
      this.password2 = "";
      this.error = {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        password2: null,
        register: null,
        login: null,
      };
      this.loading = false;
    },
    firstname() {
      if (this.error.firstname) {
        this.checkFirstnameValidity();
      }
    },
    lastname() {
      if (this.error.lastname) {
        this.checkLastnameValidity();
      }
    },
    email() {
      if (this.error.email) {
        this.checkEMailValidity();
      }
    },
    password() {
      if (this.error.password) {
        if (this.currentScreen == this.SCREEN_LOGIN) {
          this.checkPasswortLoginValidity();
        } else {
          this.checkPasswortValidity();
        }
      }
    },
    password2() {
      if (this.error.password2) {
        this.checkPasswort2Validity();
      }
    },
  },
  methods: {
    openLogin: function (message) {
      this.loginMessage = message;
      this.currentScreen = this.SCREEN_LOGIN;
      this.openModal = true;
    },
    openUserRegistration: function () {
      this.currentScreen = this.SCREEN_REGISTER;
      this.openModal = true;
    },
    closeModal: function () {
      this.openModal = false;
      this.currentScreen = this.SCREEN_LOGIN;
    },
    createAccount: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.checkFirstnameValidity();
        this.checkLastnameValidity();
        this.checkEMailValidity();
        this.checkPasswortValidity();
        this.checkPasswort2Validity();
        return;
      }
      this.loading = true;
      this.error.register = null;

      this.$EventBus.$emit("gtag", {
        event: "account",
        schritt: "register",
      });
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/user/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            this.currentScreen = this.SCREEN_REGISTER_THANKYOU;
          } else {
            if (result.error == "102") {
              this.$bvModal.show("timeout-modal");
            } else {
              this.error.register = this.$t("errorCreate");
            }
          }
          this.loading = false;
        });
    },
    login: function () {
      this.$v.$touch();
      if (this.$v.email.$anyError || !this.$v.password.required) {
        this.checkEMailValidity();
        this.checkPasswortLoginValidity();
        return;
      }
      this.loading = true;
      this.error.login = null;

      this.$EventBus.$emit("gtag", {
        event: "account",
        schritt: "login",
      });
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            if (result.user.needPasswordChange) {
              this.$router.push({
                name: "UserPasswordReset",
                params: {
                  code: result.user.resetCode,
                  mode: result.user.needPasswordChange,
                  isPartner: result.user.isPartner,
                },
              });
            } else {
              this.$user = result.user;
              this.$user.loggedIn = true;
              this.email = null;
              this.password = null;
              if (!this.loginMessage) {
                this.$router.push("/user/account");
                if (
                  this.$user.isPartner &&
                  new Date() < new Date("2023-11-18")
                ) {
                  this.$bvModal.show("modal-webinar");
                }
                if (
                  this.$user.isPartner &&
                  new Date() < new Date("2024-06-01")
                ) {
                  this.$bvModal.show("modal-partner-ambassador");
                }
              }
            }
            this.closeModal();
          } else {
            if (result.error == "102") {
              this.$bvModal.show("timeout-modal");
            } else {
              this.error.login = this.$t("errorLogin");
            }
          }
          this.loading = false;
        });
    },
    resetPassword: function () {
      this.$v.$touch();
      if (this.$v.email.$anyError) {
        this.checkEMailValidity();
        return;
      }
      this.loading = true;
      this.error.reset = null;

      this.$EventBus.$emit("gtag", {
        event: "account",
        schritt: "reset",
        teilschritt: "request",
      });
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/user/reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            this.currentScreen = this.SCREEN_RESET_THANKYOU;
          } else {
            if (result.error == "102") {
              this.$bvModal.show("timeout-modal");
            } else {
              this.error.reset = this.$t("errorReset");
            }
          }
          this.loading = false;
        });
    },
    checkFirstnameValidity: function () {
      if (!this.$v.firstname.required) {
        this.error.firstname = this.$t("errorInputFirstname");
      } else {
        this.error.firstname = null;
      }
    },
    checkLastnameValidity: function () {
      if (!this.$v.lastname.required) {
        this.error.lastname = this.$t("errorInputLastname");
      } else {
        this.error.lastname = null;
      }
    },
    checkEMailValidity: function () {
      if (!this.$v.email.required) {
        this.error.email = this.$t("errorInputEMail");
      } else {
        if (!this.$v.email.email) {
          this.error.email = this.$t("errorInputEMailInvalid");
        } else {
          this.error.email = null;
        }
      }
    },
    checkPasswortValidity: function () {
      if (!this.$v.password.required) {
        this.error.password = this.$t("errorInputPassword");
      } else {
        if (!this.$v.password.minLength) {
          this.error.password = this.$t("errorInputPasswordTooShort");
        } else {
          if (!this.$v.password.passwordStrong) {
            this.error.password = this.$t("errorInputPasswordNotComplex");
          } else {
            this.error.password = null;
          }
        }
      }
    },
    checkPasswortLoginValidity: function () {
      if (!this.$v.password.required) {
        this.error.password = this.$t("errorInputLoginPassword");
      } else {
        this.error.password = null;
      }
    },
    checkPasswort2Validity: function () {
      if (!this.$v.password2.required) {
        this.error.password2 = this.$t("errorInputPassword2");
      } else {
        if (!this.$v.password2.sameAsPassword) {
          this.error.password2 = this.$t("errorInputPassword2NotSame");
        } else {
          this.error.password2 = null;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/vue/styles";
.modal-content,
.aside {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-content {
  border: none;
}
.form {
  width: 100%;
  &__element {
    margin: var(--spacer-base) 0;
  }
  &__checkbox {
    margin: var(--spacer-xl) 0 var(--spacer-2xl) 0;
  }
  &__submit {
    margin: var(--spacer-xl) 0 0 0;
  }
}
.action-button {
  margin: var(--spacer-xl) 0;
}
.aside {
  margin: 0 0 var(--spacer-xl) 0;
  &__heading {
    --heading-title-color: var(--zimply-dark-green);
    --heading-title-font-size: var(--h3-font-size);
    --heading-title-font-weight: var(--font-weight--semibold);
    margin: 0 0 var(--spacer-sm) 0;
  }
}
</style>
<style>
.rounded > div {
  border-radius: 10px;
}
#login .sf-bar__title {
  font-size: 1.1875rem; /* 19px */
  font-weight: 550;
  text-transform: uppercase;
}
</style>
