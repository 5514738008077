<template>
  <b-modal
    id="modal-sendmixture"
    :title="$t('title')"
    title-class="f-h1"
    centered
    size="lg"
    @ok="sendMixture"
    :ok-disabled="running"
    :cancel-title="$t('buttonCancel')"
  >
    <template #modal-ok>
      <b-spinner v-if="running" small></b-spinner>
      Absenden
    </template>
    <send-mixture
      ref="sendMixture"
      :mixtures="[mixture_num]"
      :firstname="firstname"
      :partner="partner"
      :formalVariantInit="false"
    />
  </b-modal>
</template>

<script>
import SendMixture from "./SendMixture.vue";

export default {
  name: "ModalSendMixture",
  props: ["mixture_num", "firstname", "partner"],
  components: { SendMixture },
  i18nOptions: { keyPrefix: "sendMixture" },
  data: function () {
    return {
      running: false,
    };
  },
  methods: {
    sendMixture: function (bvModalEvent) {
      bvModalEvent.preventDefault();
      this.running = true;
      this.$refs.sendMixture.sendMixture().then((result) => {
        if (result) {
          this.$bvModal.hide("modal-sendmixture");
          this.$bvToast.toast("Deine Nachricht wurde verschickt", {
            noCloseButton: true,
            toaster: "b-toaster-top-center",
          });
        }
        this.running = false;
      });
    },
  },
};
</script>

<style>
#modal-sendmixture .modal-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.b-toaster {
  z-index: 2200;
}
</style>
