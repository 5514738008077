<template>
  <div class="question-wrapper">
    <b-container
      v-if="step.question.pretitle"
      fluid="xxl"
      class="text-center f-p1 mb-3"
    >
      <div class="pretitle">
        {{ step.question.pretitle }}
      </div>
    </b-container>
    <div class="header banner question">
      <h1 class="m-2 m-md-0">{{ step.question.question }}</h1>
      <div class="context-info mr-2 mr-md-0" v-if="step.question.context">
        <b-button
          class="info-icon"
          :id="'info-icon-' + step.question.id"
          variant="link"
          href="#"
          tabindex="0"
        >
          <BIconInfoCircleFill v-if="contextInfoShown" scale="1.6" />
          <BIconInfoCircle v-if="!contextInfoShown" scale="1.6" />
        </b-button>
        <b-popover
          custom-class="info-popover f-p3"
          :target="'info-icon-' + step.question.id"
          placement="bottomleft"
          :triggers="$isMobile ? 'click blur' : 'hover'"
          @hidden="contextInfoShown = false"
          @shown="contextInfoShown = true"
        >
          {{ step.question.context }}
        </b-popover>
      </div>
    </div>
    <b-container fluid="xxl" class="question-content-wrapper f-p1 my-5">
      <div class="content">
        <div v-if="step.question.description" class="description">
          <template v-if="step.question.description.startsWith('$')">
            {{
              $t(step.question.id, {
                context: step.question.description.slice(1),
              })
            }}
          </template>
          <template v-else>
            {{ step.question.description }}
          </template>
        </div>
        <slot></slot>
        <div v-if="step.question.collapse_description" class="description mt-5">
          <b-link v-b-toggle.collapse-description
            >{{ $t(step.question.id, { context: "CollapseDescTitle" })
            }}<BIconChevronDown class="ml-3" scale="1.4"
          /></b-link>
          <b-collapse id="collapse-description" class="mt-3">
            <div class="description">
              {{ $t(step.question.id, { context: "CollapseDescDesc" }) }}
            </div>
          </b-collapse>
        </div>
        <div v-if="step.question.footnote" class="footnote f-p4">
          {{ step.question.footnote }}
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BIconInfoCircle,
  BIconInfoCircleFill,
  BIconChevronDown,
} from "bootstrap-vue";

export default {
  name: "QuestionBase",
  components: {
    BIconInfoCircle,
    BIconInfoCircleFill,
    BIconChevronDown,
  },
  props: ["step"],
  i18nOptions: { keyPrefix: "question" },
  data: function () {
    return {
      contextInfoShown: false,
    };
  },
  mounted() {
    if (this.$isMobile) {
      if (this.step.question.type != "start") {
        const tb = document.querySelectorAll(
          'div[id^="trustbadge-container-"]'
        );
        if (tb.length > 0) tb[0].style.display = "none";
      }
    }
  },
  destroyed: function () {
    if (this.$isMobile) {
      const tb = document.querySelectorAll('div[id^="trustbadge-container-"]');
      if (tb.length > 0) tb[0].style.display = "";
    }
  },
};
</script>

<style scoped>
.description .collapsed .bi-chevron-down {
  transition: all 0.25s ease-in-out;
}
.description .not-collapsed .bi-chevron-down {
  transform: rotate(180deg);
  transition: all 0.25s ease-in-out;
}
</style>
<style>
.question-content-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}
.pretitle {
  text-align: center;
  font-family: var(--text-montserrat);
  font-weight: 700;
}
.question {
  text-align: center;
}
.question-content-wrapper .content {
  padding-top: 3.5rem;
  text-align: center;
}
.content .description {
  padding-bottom: 1rem;
}
.content .description a {
  color: black;
}
.footnote {
  padding-top: 3rem;
}
.number-input-wrapper {
  position: relative;
  padding-bottom: 1rem;
}
.number-input {
  max-width: 15rem;
  display: inline-block;
}
.number-input-unit {
  font-size: 1.2rem;
  position: absolute;
  margin-top: 9px;
  margin-left: -65px;
  color: #6c757d;
  opacity: 1;
}
.error {
  color: red;
}
.question-content-wrapper .content .btn {
  margin-bottom: 0.3rem;
  margin-right: 0.3rem;
  line-height: 2.5rem;
}
.btn-group button,
.btn-group-vertical button,
.btn-group.short .btn,
.btn-group-vertical.short .btn {
  min-width: 175px;
}
.btn-group .btn.special-option {
  margin-left: 10px;
}
.btn-group-vertical .btn.special-option {
  margin-top: 10px;
}
@media only screen and (min-width: 600px) {
  .question,
  .content {
    margin-left: auto;
    margin-right: auto;
  }
  .question h1 {
    width: 70%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (max-width: 600px) {
  .btn-group button {
    min-width: 125px;
  }
}

.question {
  position: relative;
}
@media only screen and (min-width: 600px) {
  .context-info {
    position: absolute;
    right: 2rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .context-info {
    position: absolute;
    right: 0;
    top: 0;
  }
  .context-info .btn .b-icon.bi {
    font-size: 100%;
  }
}
.info-icon {
  color: var(--zimply-black);
}
.info-icon:hover {
  color: var(--zimply-dark-green);
}
.info-icon.focus,
.info-icon:focus {
  box-shadow: none;
}
.info-popover {
  background-color: var(--zimply-beige);
}

/* Transition */
.t-ud-enter-active .context-info,
.t-ud-leave-active .context-info {
  display: none;
}

/* Button-Icons/Spinner */
.question-content-wrapper .content .btn {
  padding-left: 35px;
  padding-right: 35px;
}
.question-content-wrapper .content .btn .b-icon {
  position: absolute;
  left: 5px;
  top: 0;
  height: 100%;
}
.question-content-wrapper .content .btn .spinner-border {
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -0.5rem;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.question-content-wrapper .content .btn .b-icon.zoom {
  animation-name: zoom;
  animation-duration: 1s;
}
</style>
