var render = function render(){var _vm=this,_c=_vm._self._c;return _c('QuestionBase',{staticClass:"qstart",attrs:{"step":_vm.step}},[_c('h2',[_c('i18next',{attrs:{"translation":_vm.$t('title')},scopedSlots:_vm._u([{key:"lineBreak",fn:function(){return [_c('br')]},proxy:true}])})],1),_vm._v(" "),_c('div',[_vm._v("\n    "+_vm._s(_vm.$t("desc"))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"banner py-3"},[_vm._v("\n    "+_vm._s(_vm.$t("mixtureQuantity", {
        quantity: this.formatNumber(this.$znc_stats.configs, 0, 0),
      }))+"\n  ")]),_vm._v(" "),_c('b-container',{staticClass:"infos f-p2",attrs:{"fluid":""}},[_c('b-row',{staticClass:"my-3",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pharmacy",attrs:{"cols":"4"}},[_c('b-img',{attrs:{"src":_vm.$ZNC_BACKEND_BASE + '/ui/img-pharmacy/' + _vm.$pharmacy.id + '.png',"width":"100","alt":_vm.$pharmacy.contact.salutation +
            ' ' +
            _vm.$pharmacy.contact.title +
            ' ' +
            _vm.$pharmacy.contact.firstname +
            ' ' +
            _vm.$pharmacy.contact.lastname}}),_vm._v(" "),_c('div',[_vm._v("\n          "+_vm._s(_vm.$t("pharmacistSalutation", {
              context: _vm.$pharmacy.contact.salutation,
            }))),_c('br'),_vm._v("\n          "+_vm._s(_vm.$pharmacy.contact.title)+"\n          "+_vm._s(_vm.$pharmacy.contact.firstname)+"\n          "+_vm._s(_vm.$pharmacy.contact.lastname)+"\n        ")])],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"8"}},[_c('ul',{staticClass:"benefits"},[_c('li',[_vm._v(_vm._s(_vm.$t("benefits_1")))]),_vm._v(" "),_c('li',[_vm._v(_vm._s(_vm.$t("benefits_2")))]),_vm._v(" "),_c('li',[_vm._v(_vm._s(_vm.$t("benefits_3")))])])])],1)],1),_vm._v(" "),_c('div',{staticClass:"banner py-3"},[_c('div',{staticClass:"nps-stars"},[_c('BIconStarFill',{attrs:{"scale":"1.5"}}),_vm._v(" "),_c('BIconStarFill',{attrs:{"scale":"1.5"}}),_vm._v(" "),_c('BIconStarFill',{attrs:{"scale":"1.5"}}),_vm._v(" "),_c('BIconStarFill',{attrs:{"scale":"1.5"}}),_vm._v(" "),_c('BIconStarHalf',{attrs:{"scale":"1.5"}})],1),_vm._v("\n    "+_vm._s(_vm.$t("starsDesc"))+"\n  ")]),_vm._v(" "),_c('div',[_c('b-button',{staticClass:"position-relative",attrs:{"href":"#","block":"","variant":"primary","size":"lg"},on:{"click":function($event){return _vm.$emit('next_without')}}},[(_vm.running)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.$t("buttonStart"))+"\n    ")],1)],1),_vm._v(" "),_c('div',{staticClass:"f-p3"},[_c('i18next',{attrs:{"translation":_vm.$t('backgroundInfo')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BIconInfoCircle')]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }