<template>
  <QuestionBase :step="step">
    <b-form-checkbox
      v-model="answer"
      value="accepted"
      unchecked-value="not_accepted"
      :class="error ? 'error' : ''"
      size="lg"
    >
      {{ $t("Disclaimer_confirmation") }}
    </b-form-checkbox>

    <b-row class="justify-content-end m-0 mobile-justify-center mt-3">
      <b-button
        href="#"
        variant="green"
        size="lg"
        @click="$emit('answer_and_next', answer)"
        class="col-12 col-md-2 mw-content"
      >
        <b-spinner v-if="running" small></b-spinner>
        {{ $t("buttonNext") }}
      </b-button>
    </b-row>
  </QuestionBase>
</template>

<script>
import QuestionBase from "@/components/QuestionBase.vue";

export default {
  name: "QuestionConfirm",
  components: {
    QuestionBase,
  },
  props: ["step", "answers", "error", "running"],
  i18nOptions: { keyPrefix: "question" },
  data: function () {
    return {
      answer: this.answers[this.step.question.id],
    };
  },
};
</script>
