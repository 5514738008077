<template>
  <QuestionBase :step="step" class="qstart">
    <h2>
      <i18next :translation="$t('title')">
        <template #lineBreak>
          <br />
        </template>
      </i18next>
    </h2>
    <div>
      {{ $t("desc") }}
    </div>
    <div class="banner py-3">
      {{
        $t("mixtureQuantity", {
          quantity: this.formatNumber(this.$znc_stats.configs, 0, 0),
        })
      }}
    </div>
    <b-container fluid class="infos f-p2">
      <b-row no-gutters class="my-3">
        <b-col cols="4" class="pharmacy">
          <b-img
            :src="
              $ZNC_BACKEND_BASE + '/ui/img-pharmacy/' + $pharmacy.id + '.png'
            "
            width="100"
            :alt="
              $pharmacy.contact.salutation +
              ' ' +
              $pharmacy.contact.title +
              ' ' +
              $pharmacy.contact.firstname +
              ' ' +
              $pharmacy.contact.lastname
            "
          ></b-img>
          <div>
            {{
              $t("pharmacistSalutation", {
                context: $pharmacy.contact.salutation,
              })
            }}<br />
            {{ $pharmacy.contact.title }}
            {{ $pharmacy.contact.firstname }}
            {{ $pharmacy.contact.lastname }}
          </div>
        </b-col>
        <b-col cols="8">
          <ul class="benefits">
            <li>{{ $t("benefits_1") }}</li>
            <li>{{ $t("benefits_2") }}</li>
            <li>{{ $t("benefits_3") }}</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <div class="banner py-3">
      <div class="nps-stars">
        <BIconStarFill scale="1.5" />
        <BIconStarFill scale="1.5" />
        <BIconStarFill scale="1.5" />
        <BIconStarFill scale="1.5" />
        <BIconStarHalf scale="1.5" />
      </div>
      {{ $t("starsDesc") }}
    </div>
    <div>
      <b-button
        href="#"
        block
        variant="primary"
        size="lg"
        @click="$emit('next_without')"
        class="position-relative"
      >
        <b-spinner v-if="running" small></b-spinner>
        {{ $t("buttonStart") }}
      </b-button>
    </div>
    <div class="f-p3">
      <i18next :translation="$t('backgroundInfo')">
        <template #icon>
          <BIconInfoCircle />
        </template>
      </i18next>
    </div>
  </QuestionBase>
</template>

<script>
import { BIconInfoCircle, BIconStarFill, BIconStarHalf } from "bootstrap-vue";
import QuestionBase from "@/components/QuestionBase.vue";

export default {
  name: "QuestionStart",
  components: {
    BIconInfoCircle,
    BIconStarFill,
    BIconStarHalf,
    QuestionBase,
  },
  props: ["step", "running"],
  i18nOptions: { keyPrefix: "questionStart" },
};
</script>

<style>
.qstart .question-content-wrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.qstart .question-content-wrapper .content {
  padding-top: 0rem;
  max-width: 600px;
}
.qstart .question-content-wrapper .content > div,
.qstart .question-content-wrapper .content > h2 {
  padding-left: 5px;
  padding-right: 5px;
}
.qstart .infos {
  padding: 0;
}
.qstart .pharmacy div {
  font-style: italic;
  font-weight: 700;
  font-size: 0.9rem;
}
.qstart ul.benefits {
  padding-left: 2rem;
  padding-top: 0.2rem;
  margin-bottom: 0;
  text-align: left;
}
.qstart .benefits li {
  list-style-type: none;
  position: relative;
}
.qstart .benefits li:before {
  content: "✓";
  position: absolute;
  left: -1.4em;
}
.qstart .btn {
  margin-top: 10px;
}
.nps-stars {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--star-yellow);
}
.nps-stars .b-icon {
  margin-left: 7px;
  margin-right: 7px;
}
</style>
