import Vue from "vue";
import VueRouter from "vue-router";
import StartView from "../views/StartView.vue";
import QuestionaryView from "../views/QuestionaryView.vue";
import CheckoutView from "../views/CheckoutView.vue";
import ThankyouView from "../views/ThankyouView.vue";
import DirectOrder from "../components/DirectOrder.vue";
import GiftVoucherView from "../views/GiftVoucherView.vue";
import ProductView from "../views/ProductView.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Start", component: StartView },
  {
    path: "/gut-schlafen",
    name: "Schlafen",
    component: QuestionaryView,
    props: { branch: "Schlafen" },
  },
  { path: "/schlafen", redirect: { name: "Schlafen" } },
  {
    path: "/stresslos-entspannt",
    name: "Stress",
    component: QuestionaryView,
    props: { branch: "Stress" },
  },
  { path: "/stress", redirect: { name: "Stress" } },
  {
    path: "/kopf-frei",
    name: "Kopf",
    component: QuestionaryView,
    props: { branch: "Kopf" },
  },
  { path: "/kopf", redirect: { name: "Kopf" } },
  {
    path: "/heuschnupfen",
    name: "Heu",
    component: QuestionaryView,
    props: { branch: "Heu" },
  },
  { path: "/heu", redirect: { name: "Heu" } },
  {
    path: "/immunsystem",
    name: "Immun",
    component: QuestionaryView,
    props: { branch: "Immun" },
  },
  { path: "/immun", redirect: { name: "Immun" } },
  {
    path: "/sodbrennen",
    name: "Sod",
    component: QuestionaryView,
    props: { branch: "Sod" },
  },
  { path: "/sod", redirect: { name: "Sod" } },
  {
    path: "/wechseljahre",
    name: "Wechsel",
    component: QuestionaryView,
    props: { branch: "Wechsel" },
  },
  { path: "/wechsel", redirect: { name: "Wechsel" } },
  {
    path: "/periode",
    name: "Periode",
    component: QuestionaryView,
    props: { branch: "Periode" },
  },
  {
    path: "/akne",
    name: "Akne",
    component: QuestionaryView,
    props: { branch: "Akne" },
  },
  {
    path: "/motivation",
    name: "Motivation",
    component: QuestionaryView,
    props: { branch: "Motivation" },
  },
  {
    path: "/herpes",
    name: "Herpes",
    component: QuestionaryView,
    props: { branch: "Herpes" },
  },
  {
    path: "/individual",
    name: "Individual",
    component: QuestionaryView,
    props: { branch: "Individual" },
  },
  {
    path: "/bestellung/:product_num?",
    name: "Checkout",
    component: CheckoutView,
  },
  { path: "/thankyou", name: "Thankyou", component: ThankyouView },
  {
    path: "/schnellbestellung",
    name: "DirectOrder",
    component: DirectOrder,
    props: (route) => ({ mixture: route.query.m, name: route.query.n }),
  },
  {
    path: "/geschenkgutschein",
    name: "GiftVoucher",
    component: GiftVoucherView,
  },
  {
    path: "/p/:product_num/:firstname?",
    name: "ProductView",
    component: ProductView,
    props: (route) => ({
      product_num: route.params.product_num,
      firstname: route.params.firstname,
    }),
  },
  {
    path: "/user/verify-email",
    name: "UserAccountActivation",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "../views/UserAccountActivation.vue"
      ),
    props: (route) => ({ code: route.query.code }),
  },
  {
    path: "/user/reset",
    name: "UserPasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "../views/UserPasswordReset.vue"
      ),
    props: (route) => ({
      code: route.query.code || route.params.code,
      mode: route.params.mode,
      isPartner: route.params.isPartner,
    }),
  },
  {
    path: "/user/registration",
    name: "UserAccountRegistration",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "../views/UserAccountRegistration.vue"
      ),
  },
  {
    path: "/user/account/:pageName?/:subPageName?/:action?",
    name: "UserAccount",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/UserAccount.vue"),
  },
  {
    path: "/vertraege-kuendigen",
    name: "SubscriptionTermination",
    component: () =>
      import(
        /* webpackChunkName: "subscr-term" */ "../views/SubscriptionTermination.vue"
      ),
  },
  { path: "*", component: StartView },
];

// workaround for ugly errors "Avoided redundant navigation to current location"
const origPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (to) {
  const match = this.matcher.match(to);
  if (match.fullPath !== this.currentRoute.fullPath) {
    return origPush.call(this, to);
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0, behavior: "smooth" });
        }, 10);
      });
    }
  },
});

export default router;
