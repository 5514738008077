<template>
  <div class="gift-voucher-wrapper">
    <div class="header banner">
      <h1>{{ $t("headline") }}</h1>
    </div>
    <b-container fluid="xxl" class="gift-voucher f-p1 my-5">
      <b-row class="text-center mb-5 px-4">
        {{ $t("desc") }}
      </b-row>
      <b-row class="align-items-center">
        <b-col cols="12" md="5" class="justify-content-center text-center px-4">
          <div class="img-container">
            <b-img src="@/assets/spray.png" class="mixture-bottle"></b-img>
          </div>
        </b-col>
        <b-col cols="12" md="7" class="px-4">
          <b-row>
            <b-col col>
              {{ $t("benefits", { context: "caption" }) }}
              <ul class="benefits f-p2">
                <li>{{ $t("benefits", { context: "1" }) }}</li>
                <li>{{ $t("benefits", { context: "2" }) }}</li>
                <li>{{ $t("benefits", { context: "3" }) }}</li>
                <li>{{ $t("benefits", { context: "4" }) }}</li>
              </ul>
            </b-col>
          </b-row>
          <b-row class="py-2">
            <b-col col>
              <b-form-input
                size="lg"
                v-model.trim="firstname"
                class="firstname f-p1"
                type="text"
                :placeholder="$t('firstnameInput')"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="py-2">
            <b-col cols="12" class="price">{{ formatCurrency(29.99) }}</b-col>
            <b-col cols="12" class="f-p4">{{ $t("taxInfo") }}</b-col>
          </b-row>
          <b-row>
            <b-col col>
              <b-button
                @click="addToCart()"
                href="#"
                variant="primary"
                class="f-p1"
              >
                {{ $t("buyButton") }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "GiftVoucherView",
  i18nOptions: { keyPrefix: "giftvoucher" },
  metaInfo: function () {
    return {
      title: "ZIMPLY NATURAL - " + this.$t("headline"),
    };
  },
  data: function () {
    return {
      firstname: null,
    };
  },
  mounted() {
    this.$EventBus.$emit("gtag", {
      event: "konfigurator-geschenkgutschein",
      schritt: "start",
    });
  },
  methods: {
    addToCart: function () {
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product: "ZIMPLY.GGS",
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, product]) => {
          if (status == 200) {
            let item = {
              product: product,
              id: product.num,
              type: product.type,
              name: product.details.iname
                ? product.details.iname
                    .replace(
                      "<Individualisierung>",
                      this.firstname
                        ? this.$t("firstnameProductPrefix") +
                            " " +
                            this.firstname
                        : ""
                    )
                    .replace(
                      this.$t("firstnameProductPrefix") + " <Vorname>",
                      this.firstname
                        ? this.$t("firstnameProductPrefix") +
                            " " +
                            this.firstname
                        : ""
                    )
                : product.details.name,
              deliverytime: product.details.deliverytime,
              count: 1,
              price: product.price,
              sum: product.price,
            };
            this.$cart.push(item);
            this.$EventBus.$emit("gtag", {
              event: "konfigurator-geschenkgutschein",
              schritt: "checkout",
            });
            this.$EventBus.$emit("track_goal", 3);
            this.$router.push("/bestellung");
          } else {
            // ignore wrong product
          }
        });
    },
  },
};
</script>

<style>
.gift-voucher > div {
  padding-left: 5px;
  padding-right: 5px;
}
.gift-voucher .banner {
  font-style: italic;
  font-weight: bold;
  background-color: var(--blue);
  color: var(--green);
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gift-voucher ul.benefits {
  padding-left: 2rem;
  padding-top: 0.2rem;
  text-align: left;
}
.gift-voucher .benefits li {
  list-style-type: none;
  position: relative;
}
.gift-voucher .benefits li:before {
  content: "✓";
  position: absolute;
  left: -1.4em;
}
.gift-voucher .btn {
  min-width: 250px;
  text-align: center;
}
.gift-voucher .firstname {
  max-width: 20rem;
  display: inline-block;
  margin-bottom: 10px;
}
</style>
