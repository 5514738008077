<template>
  <QuestionBase :step="step">
    <div class="d-none">{{ value }}</div>
    <b-button-group
      :vertical="
        step.question.display == 'vertical' || $isMobile ? true : false
      "
    >
      <b-button
        v-for="option in step.question.options"
        :key="option.id"
        variant="outline-dark"
        size="lg"
        :class="answers[step.question.id] == option.id ? 'active' : ''"
        @click="submit(option.id)"
      >
        <BIconCheck
          v-if="!running && answers[step.question.id] == option.id"
          :class="cls"
        />
        <b-spinner
          v-if="running && answers[step.question.id] == option.id"
          small
        ></b-spinner>
        {{ $t(step.question.id, { context: option.id }) }}
      </b-button>

      <b-button
        v-if="step.question.special_option"
        variant="outline-dark"
        size="lg"
        class="special-option"
        :class="
          answers[step.question.id] == step.question.special_option.id
            ? 'active'
            : ''
        "
        @click="submit(step.question.special_option.id)"
      >
        <BIconCheck
          v-if="
            !running &&
            answers[step.question.id] == step.question.special_option.id
          "
          :class="cls"
        />
        <b-spinner
          v-if="
            running &&
            answers[step.question.id] == step.question.special_option.id
          "
          small
        ></b-spinner>
        {{ $t(step.question.id, { context: step.question.special_option.id }) }}
      </b-button>
    </b-button-group>
  </QuestionBase>
</template>

<script>
import { BIconCheck } from "bootstrap-vue";
import QuestionBase from "@/components/QuestionBase.vue";

export default {
  name: "QuestionOption",
  components: {
    BIconCheck,
    QuestionBase,
  },
  props: ["step", "answers", "running"],
  i18nOptions: { keyPrefix: "question" },
  data: function () {
    return {
      value: null,
      cls: null,
    };
  },
  updated() {
    let btn = document.querySelector(".btn.focus,.btn:focus");
    if (btn) btn.blur();
  },
  methods: {
    submit: function (checked) {
      this.value = checked;
      this.cls = "zoom";
      this.$emit("answer_and_next", checked, 1000);
    },
  },
};
</script>
