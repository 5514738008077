<template>
  <QuestionBase :step="step">
    <div class="d-none">{{ value }}</div>
    <b-form-checkbox-group
      v-model="answer"
      :state="valid"
      name="buttons-question-multiselect"
      :required="true"
      :stacked="step.question.display == 'vertical' || $isMobile ? true : false"
      :class="step.question.display == 'vertical' || $isMobile ? '' : 'short'"
      button-variant="outline-dark"
      size="lg"
      buttons
    >
      <b-form-checkbox
        v-for="option in step.question.options"
        :key="option.id"
        :value="option.id"
        @change="onChange(option.id)"
      >
        <BIconCheck v-if="isActive(option.id)" :class="cls[option.id]" />
        {{ $t(step.question.id, { context: option.id }) }}
      </b-form-checkbox>
    </b-form-checkbox-group>
    <b-form-invalid-feedback :state="valid">
      <span v-if="step.question.min == step.question.max">
        {{ $t("chooseExactelyOptions", { quantity: step.question.min }) }}
      </span>
      <span v-if="step.question.min != step.question.max">
        {{
          $t("chooseMinMaxOptions", {
            min: step.question.min,
            max: step.question.max,
          })
        }}
      </span>
    </b-form-invalid-feedback>
    <b-row class="justify-content-end m-0 mobile-justify-center mt-3">
      <b-button
        href="#"
        variant="green"
        size="lg"
        @click="validateAndSubmit()"
        class="col-12 col-md-2 mw-content"
      >
        <b-spinner v-if="running" small></b-spinner>
        {{ $t("buttonNext") }}
      </b-button>
    </b-row>
  </QuestionBase>
</template>

<script>
import { BIconCheck } from "bootstrap-vue";
import QuestionBase from "@/components/QuestionBase.vue";

export default {
  name: "QuestionOptionMultiselect",
  components: {
    BIconCheck,
    QuestionBase,
  },
  props: ["step", "answers", "running"],
  i18nOptions: { keyPrefix: "question" },
  data: function () {
    return {
      answer: this.answers[this.step.question.id],
      valid: null,
      value: null,
      cls: {},
    };
  },
  methods: {
    validateAndSubmit: function () {
      this.valid = null;
      let cnt = 0;
      if (this.answer != undefined) {
        cnt = this.answer.length;
      }
      if (cnt == 0) {
        this.answer = [];
      }
      if (cnt < this.step.question.min || cnt > this.step.question.max) {
        this.valid = false;
      }
      if (this.valid == null) {
        this.$emit("answer_and_next", this.answer);
      }
    },
    isActive: function (option_id) {
      if (this.answer != undefined && this.answer.includes(option_id)) {
        return true;
      }
      return false;
    },
    onChange: function (option_id) {
      this.value = option_id;
      this.cls[option_id] = "zoom";
    },
  },
};
</script>
