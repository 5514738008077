<template>
  <b-modal
    id="modal-webinar"
    centered
    size="lg"
    ok-only
    ok-variant="secondary"
    ok-title="Schließen"
    title="Unsere nächste Fachfortbildung"
  >
    <div class="text-center">
      <b-link
        href="https://www.zimplynatural.de/webinare/erkennen-und-behandeln-von-hautausschlaegen-psoriatische-diathese-versus-neurogen-lymphatische-konstitution/"
        target="_blank"
      >
        <img
          src="https://www.zimplynatural.de/wp-content/uploads/2023/10/Titelbild-Webinargeek-u.-Newsletter.jpg"
          width="100%"
        />
      </b-link>
      <b-button
        variant="primary"
        class="mt-3"
        href="https://www.zimplynatural.de/webinare/erkennen-und-behandeln-von-hautausschlaegen-psoriatische-diathese-versus-neurogen-lymphatische-konstitution/"
        target="_blank"
      >
        Jetzt informieren &amp; anmelden!
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalWebinar",
};
</script>

<style></style>
