<template>
  <QuestionBase :step="step">
    <CircleSlider
      v-model="answer"
      :min="step.question.min"
      :max="step.question.max"
      :unit="$t(step.question.id, { context: 'unit' })"
      :valid="valid"
    />
    <b-row class="justify-content-end m-0 mobile-justify-center">
      <b-button
        href="#"
        variant="green"
        size="lg"
        @click="validateAndSubmit()"
        class="col-12 col-md-2 mw-content"
      >
        <b-spinner v-if="running" small></b-spinner>
        {{ $t("buttonNext") }}
      </b-button>
    </b-row>
  </QuestionBase>
</template>

<script>
import QuestionBase from "@/components/QuestionBase.vue";
import CircleSlider from "@/components/CircleSlider.vue";

export default {
  name: "QuestionCircleSlider",
  components: {
    QuestionBase,
    CircleSlider,
  },
  props: ["step", "answers", "running"],
  i18nOptions: { keyPrefix: "question" },
  data: function () {
    return {
      answer: this.answers[this.step.question.id],
      valid: null,
    };
  },
  methods: {
    validateAndSubmit: function () {
      this.valid = null;
      if (this.answer == undefined) {
        this.valid = false;
      }
      if (this.answer < this.step.question.min) {
        this.valid = false;
      }
      if (this.valid == null) {
        this.$emit("answer_and_next", Number(this.answer));
      }
    },
  },
};
</script>

<style></style>
