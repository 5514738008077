<template>
  <b-modal
    id="modal-partner-ambassador"
    centered
    size="lg"
    ok-only
    ok-variant="secondary"
    ok-title="Schließen"
    title="Partner werben Partner"
  >
    <b-container>
      <b-row>
        <b-col cols="12" lg="6">
          <b-img src="@/assets/partner-ambassador.png" />
        </b-col>
        <b-col cols="12" lg="6" class="pt-2">
          <div>
            <p>Einladen und gemeinsam wachsen!</p>
            <p>
              Werde Partnerbotschafter und empfehle uns an andere Partner
              weiter. Es winken akttraktive Vorteile für Dich.
            </p>
            <p>
              Du möchtest mehr Infos dazu? Dann schreib mir gerne direkt eine
              Mail an:
              <b-link class="text-link" href="mailto:thekla@zimplynatural.de">
                thekla@zimplynatural.de
              </b-link>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: "ModalPartnerAmbassador",
};
</script>

<style>
#modal-partner-ambassador .modal-title {
  width: 100%;
  text-align: center;
}
#modal-partner-ambassador img {
  width: 100%;
}
</style>
