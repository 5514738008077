import Vue from "vue";

Vue.mixin({
  methods: {
    round: function (num) {
      return Number(Math.round(num + "e2") + "e-2");
    },
    formatNumber: function (
      num,
      minimumFractionDigits = 2,
      maximumFractionDigits = 2
    ) {
      return num.toLocaleString(this.$i18next.resolvedLanguage, {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
      });
    },
    formatCurrency: function (amount) {
      return this.formatNumber(amount) + " €";
    },
    formatDate: function (ts) {
      return new Date(ts).toLocaleString(this.$i18next.resolvedLanguage, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatTimestamp: function (ts) {
      return new Date(ts).toLocaleString(this.$i18next.resolvedLanguage, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    camelize: function (str) {
      return str
        .replace(/(-.)/g, (m, chr) => chr.toUpperCase())
        .replace(/(^.)/g, (m, chr) => chr.toUpperCase());
    },
    generateUuid: function () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
  },
});
