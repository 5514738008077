<template>
  <QuestionBase :step="step">
    <div class="range-slider-wrapper">
      <div id="range-slider" :class="valid == false ? 'invalid' : ''">
        <template v-for="option in step.question.options">
          <input
            type="radio"
            v-model="answer"
            :value="option.id"
            :name="step.question.id"
            :id="'range-slider-option-' + option.id"
            :key="'range-slider-option-' + option.id"
            @click="valid = null"
          />
          <label
            :for="'range-slider-option-' + option.id"
            :key="'range-slider-label-' + option.id"
            :data="$t(step.question.id, { context: option.id })"
          ></label>
        </template>
        <div id="range-slider-marker"></div>
      </div>
    </div>
    <b-row class="justify-content-end m-0 mobile-justify-center">
      <b-button
        href="#"
        variant="green"
        size="lg"
        @click="validateAndSubmit()"
        class="col-12 col-md-2 mw-content"
      >
        <b-spinner v-if="running" small></b-spinner>
        {{ $t("buttonNext") }}
      </b-button>
    </b-row>
  </QuestionBase>
</template>

<script>
import QuestionBase from "@/components/QuestionBase.vue";

export default {
  name: "QuestionRangeSlider",
  components: {
    QuestionBase,
  },
  props: ["step", "answers", "running"],
  i18nOptions: { keyPrefix: "question" },
  data: function () {
    return {
      answer: this.answers[this.step.question.id],
      valid: null,
    };
  },
  methods: {
    validateAndSubmit: function () {
      this.valid = null;
      if (this.answer == undefined) {
        this.valid = false;
      }
      if (this.valid == null) {
        this.$emit("answer_and_next", Number(this.answer));
      }
    },
  },
};
</script>

<style>
.range-slider-wrapper {
  padding-bottom: 80px;
}
#range-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  height: 50px;
  user-select: none;
}
#range-slider::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  width: calc(100% * (4 / 5));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--zimply-black);
}
#range-slider.invalid::before {
  background: var(--invalid);
}
#range-slider input,
#range-slider label {
  box-sizing: border-box;
  flex: 1;
  user-select: none;
  cursor: pointer;
}
#range-slider label {
  display: inline-block;
  position: relative;
  width: 20%;
  height: 100%;
  user-select: none;
}
#range-slider label::before {
  content: attr(data);
  position: absolute;
  left: 50%;
  padding-top: 10px;
  transform: translate(-50%, 45px);
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  opacity: 0.85;
  transition: all 0.15s ease-in-out;
}
#range-slider label::after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  box-sizing: unset;
  border: 2px solid var(--zimply-black);
  background: #fff;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
#range-slider.invalid label::after {
  border: 2px solid var(--invalid);
}
#range-slider label:hover::after {
  transform: translate(-50%, -50%) scale(1.25);
}
#range-slider input {
  display: none;
}
#range-slider input:checked + label::after {
  border-width: 4px;
  transform: translate(-50%, -50%) scale(0.75);
}
#range-slider input:checked ~ #range-slider-marker {
  opacity: 1;
}
#range-slider input:checked:nth-child(1) ~ #range-slider-marker {
  left: 10%;
}
#range-slider input:checked:nth-child(3) ~ #range-slider-marker {
  left: 30%;
}
#range-slider input:checked:nth-child(5) ~ #range-slider-marker {
  left: 50%;
}
#range-slider input:checked:nth-child(7) ~ #range-slider-marker {
  left: 70%;
}
#range-slider input:checked:nth-child(9) ~ #range-slider-marker {
  left: 90%;
}
#range-slider #range-slider-marker {
  display: block;
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background: var(--zimply-brown);
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  transform: translate(-50%, -50%);
  box-sizing: unset;
  border: 2px solid #fff;
  opacity: 0;
  z-index: 2;
}
#range-slider input + label::before {
  transform: translate(-50%, 45px) scale(0.9);
  transition: all 0.15s linear;
}
#range-slider input:checked + label::before {
  transform: translate(-50%, 45px) scale(1);
  transition: all 0.15s linear;
}
</style>
