<template>
  <b-form id="sendmixture-form" class="sendmixture-form">
    <b-container fluid>
      <b-form-row>
        <b-col>
          <b-form-checkbox v-model="formalVariant" switch>
            {{ $t("optionFormalVariant") }}
          </b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="12" md="6">
          <b-form-input
            v-model="sender.name"
            name="sender.name"
            type="text"
            required
            :placeholder="$t('inputSenderName') + '*'"
            @blur="senderNameBlur"
          ></b-form-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="12" md="6">
          <b-form-input
            v-model="recipient.name"
            name="recipient.name"
            type="text"
            required
            :placeholder="$t('inputRecipientName') + '*'"
            @blur="recipientNameBlur"
            :state="error && !$v.recipient.name.required ? false : null"
          ></b-form-input>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-input
            v-model="recipient.email"
            debounce="500"
            name="recipient.email"
            type="email"
            required
            :placeholder="$t('inputRecipientEMail') + '*'"
            :state="
              !$v.recipient.email.email ||
              (error && !$v.recipient.email.required)
                ? false
                : null
            "
          ></b-form-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-input
            v-model="subject"
            name="subject"
            type="text"
            required
            :placeholder="$t('inputSubject') + '*'"
            :state="error && !$v.subject.required ? false : null"
          ></b-form-input>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-textarea
            v-model="message"
            name="message"
            rows="10"
            max-rows="20"
            required
            :placeholder="$t('inputMessage') + '*'"
            :state="error && !$v.message.required ? false : null"
          ></b-form-textarea>
          <div class="f-p3">
            {{ $t("hintPlaceholder") }}
          </div>
        </b-col>
      </b-form-row>
      <b-form-row v-if="$user.loggedIn">
        <b-col>
          <b-form-checkbox v-model="cc" name="cc">
            {{ $t("optionCCMail") }}
          </b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <div v-if="error" class="error">
            {{ $t("errorSend") }}
          </div>
        </b-col>
      </b-form-row>
    </b-container>
  </b-form>
</template>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
Vue.use(Vuelidate);

export default {
  name: "SendMixture",
  props: ["mixtures", "firstname", "partner", "formalVariantInit"],
  i18nOptions: { keyPrefix: "sendMixture" },
  data: function () {
    return {
      old_mixtures: [],
      formalVariant: true,
      recipient: {
        email: null,
        name: null,
      },
      old_recipient_name: null,
      sender: {
        email: null,
        name: null,
      },
      old_sender_name: null,
      subject: null,
      message: null,
      cc: true,
      running: false,
      error: false,
    };
  },
  validations: {
    recipient: {
      email: {
        required,
        email,
      },
      name: {
        required,
      },
    },
    subject: {
      required,
    },
    message: {
      required,
    },
  },
  mounted() {
    this.formalVariant = this.formalVariantInit;
    this.resetForm();
  },
  watch: {
    mixtures: function () {
      this.updateMixtures();
    },
    formalVariant: function () {
      this.updateMessage();
    },
  },
  methods: {
    senderNameBlur: function () {
      if (this.sender.name == this.old_sender_name) return;

      if (this.message.endsWith(this.$t("textGreeting") + "\n")) {
        this.message += this.sender.name;
      } else if (this.message.endsWith(this.old_sender_name)) {
        this.message = this.message.slice(0, -this.old_sender_name.length);
        this.message += this.sender.name;
      }
      this.old_sender_name = this.sender.name;
    },
    recipientNameBlur: function () {
      if (this.recipient.name == this.old_recipient_name) return;

      var regex = new RegExp(this.old_recipient_name + ",\n");
      this.message = this.message.replace(regex, this.recipient.name + ",\n");

      this.old_recipient_name = this.recipient.name;
    },
    updateMixtures: function () {
      for (
        let i = this.old_mixtures.length - 1;
        i >= this.mixtures.length;
        i--
      ) {
        let to_remove = "\n[" + this.old_mixtures[i] + "]";
        this.message = this.removeLastInstance(this.message, to_remove);
      }
      for (let i = this.mixtures.length - 1; i >= 0; i--) {
        if (i < this.old_mixtures.length) {
          this.message = this.message.replace(
            "[" + this.old_mixtures[i] + "]",
            "[" + this.mixtures[i] + "]"
          );
        } else {
          this.message = this.message.replace(
            "\n \n" + this.$t("textGreeting"),
            "\n[" + this.mixtures[i] + "]\n \n" + this.$t("textGreeting")
          );
        }
      }
      this.old_mixtures = JSON.parse(JSON.stringify(this.mixtures));
    },
    removeLastInstance: function (text, to_remove) {
      let idx = text.lastIndexOf(to_remove);
      if (idx < 0) {
        if (to_remove[0] == "\n") {
          return this.removeLastInstance(text, to_remove.replace("\n", ""));
        }
        return text;
      }
      let p1 = text.substring(0, idx);
      let p2 = text.substring(idx + to_remove.length);
      return p1 + p2;
    },
    updateMessage: function () {
      if (this.formalVariant) {
        this.subject = this.subject.replace(
          this.$t("textPronounCasual"),
          this.$t("textPronounFormal")
        );
        this.message = this.message.replace(
          this.$t("textParagraph1Casual"),
          this.$t("textParagraph1Formal")
        );
        this.message = this.message.replace(
          this.$t("textParagraph2Casual"),
          this.$t("textParagraph2Formal")
        );
      } else {
        this.subject = this.subject.replace(
          this.$t("textPronounFormal"),
          this.$t("textPronounCasual")
        );
        this.message = this.message.replace(
          this.$t("textParagraph1Formal"),
          this.$t("textParagraph1Casual")
        );
        this.message = this.message.replace(
          this.$t("textParagraph2Formal"),
          this.$t("textParagraph2Casual")
        );
      }
    },
    sendMixture: function () {
      return new Promise((resolve) => {
        let formValid = document
          .getElementById("sendmixture-form")
          .reportValidity();
        this.$v.$touch();
        if (!formValid || this.$v.$anyError) {
          resolve(false);
          return;
        }

        this.running = true;
        this.error = false;
        let partner = this.partner;
        if (this.$user.loggedIn && this.$user.isPartner) {
          partner = this.$user.partnerKey;
        }

        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/send-mixture", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sender: this.sender,
            recipient: this.recipient,
            subject: this.subject,
            message: this.message,
            partner: partner,
            cc: this.cc,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.running = false;
            if (result.success) {
              resolve(true);
            } else {
              this.error = true;
              resolve(false);
            }
          });
      });
    },
    resetForm: function () {
      this.old_mixtures = [];
      this.recipient = {
        email: null,
        name: this.firstname ? this.firstname : "",
      };
      this.old_recipient_name = this.recipient.name;
      this.old_sender_name = null;
      this.subject = this.$t("textSubject");
      this.message =
        "Hallo " +
        this.recipient.name +
        ",\n \n" +
        this.$t("textParagraph1Casual") +
        " " +
        this.$t("textParagraph2Casual") +
        " \n" +
        "\n \n" +
        this.$t("textGreeting") +
        "\n";
      this.updateMessage();
      this.updateMixtures();
      if (this.$user.loggedIn) {
        this.sender.name = this.$user.firstname + " " + this.$user.lastname;
        this.sender.email = this.$user.email;
        this.senderNameBlur();
      }
    },
  },
};
</script>

<style>
.sendmixture-form .form-row div {
  margin-top: 0.5rem;
}
</style>
