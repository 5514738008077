<template>
  <div class="thankyou-wrapper">
    <div class="header banner">
      <h1>{{ $t("headline") }}</h1>
    </div>
    <div class="thankyou-content-wrapper container-xxl my-5">
      <div class="content f-p1">
        <h2>{{ $t("title") }}</h2>
        <div class="content">
          <div class="text">
            <i18next :translation="$t('desc')">
              <template #company>
                <span class="text-nowrap">ZIMPLY NATURAL</span>
              </template>
              <template #linebreak>
                <br />
              </template>
              <template #orderNumber>
                {{ $order_info.orderId }}
              </template>
            </i18next>
          </div>
          <div v-if="!$user.loggedIn" class="additional-info mt-5">
            <h2>{{ $t("noAccountCaption") }}</h2>
            <div class="text">
              {{ $t("noAccountDesc") }}<br />
              <b-link
                class="registration-link"
                @click="$EventBus.$emit('open_user_registration')"
                >{{ $t("noAccountButton") }}</b-link
              >
            </div>
          </div>
          <div id="trustedShopsCheckout" style="display: none">
            <span id="tsCheckoutOrderNr">{{ $order_info.orderId }}</span>
            <span v-if="!analyticsDisabled" id="tsCheckoutBuyerEmail">{{
              $order_info.email
            }}</span>
            <span id="tsCheckoutOrderAmount">{{ $order_info.sum }}</span>
            <span id="tsCheckoutOrderCurrency">EUR</span>
            <span id="tsCheckoutOrderPaymentType">VORKASSE</span>
            <span id="tsCheckoutOrderEstDeliveryDate">{{
              $order_info.estDeliveryDate
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <b-container fluid="fluid" class="banner ptb-60">
      <b-container fluid="xxl">
        <b-row class="align-items-center">
          <b-col cols="12" md="8" class="pb-0">
            <h2>{{ $t("giveASprayTitle") }}</h2>
            <div>
              {{ $t("giveASprayDesc") }}
            </div>
          </b-col>
          <b-col cols="12" md="4" class="pb-0 mt-3 mt-md-0">
            <b-button
              variant=""
              class="btn-green"
              size="lg"
              id="button-gift-voucher"
              to="geschenkgutschein"
            >
              {{ $t("giveASprayButton") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-container fluid="xxl">
      <b-row class="align-items-center justify-content-center my-4">
        <b-button
          class="mt-3 primsize"
          :href="$t('backToStartURL')"
          variant="green"
        >
          {{ $t("backToStart") }}
        </b-button>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ThankyouView",
  i18nOptions: { keyPrefix: "thankyou" },
  metaInfo: function () {
    return {
      title: "ZIMPLY NATURAL - " + this.$t("headline"),
    };
  },
  mounted() {
    /*global trustbadge */
    trustbadge.remove();
    trustbadge.reInitialize();
  },
  computed: {
    analyticsDisabled: function () {
      return this.$ZNC_ENV != "PROD";
    },
  },
};
</script>

<style>
.thankyou-content-wrapper .content {
  padding-top: 1rem;
  text-align: center;
}
.thankyou-content-wrapper .text {
  padding-left: 5px;
  padding-right: 5px;
}
.thankyou-content-wrapper .btn {
  font-weight: 700;
}
.additional-info {
  padding-top: 1rem;
}
.registration-link {
  color: var(--zimply-brown);
}
.registration-link:hover {
  color: var(--zimply-light-green);
  text-decoration: none;
}
</style>
