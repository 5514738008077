var render = function render(){var _vm=this,_c=_vm._self._c;return _c('QuestionBase',{attrs:{"step":_vm.step}},[_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.value))]),_vm._v(" "),_c('b-button-group',{attrs:{"vertical":_vm.step.question.display == 'vertical' || _vm.$isMobile ? true : false}},[_vm._l((_vm.step.question.options),function(option){return _c('b-button',{key:option.id,class:_vm.answers[_vm.step.question.id] == option.id ? 'active' : '',attrs:{"variant":"outline-dark","size":"lg"},on:{"click":function($event){return _vm.submit(option.id)}}},[(!_vm.running && _vm.answers[_vm.step.question.id] == option.id)?_c('BIconCheck',{class:_vm.cls}):_vm._e(),_vm._v(" "),(_vm.running && _vm.answers[_vm.step.question.id] == option.id)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.$t(_vm.step.question.id, { context: option.id }))+"\n    ")],1)}),_vm._v(" "),(_vm.step.question.special_option)?_c('b-button',{staticClass:"special-option",class:_vm.answers[_vm.step.question.id] == _vm.step.question.special_option.id
          ? 'active'
          : '',attrs:{"variant":"outline-dark","size":"lg"},on:{"click":function($event){return _vm.submit(_vm.step.question.special_option.id)}}},[(
          !_vm.running &&
          _vm.answers[_vm.step.question.id] == _vm.step.question.special_option.id
        )?_c('BIconCheck',{class:_vm.cls}):_vm._e(),_vm._v(" "),(
          _vm.running &&
          _vm.answers[_vm.step.question.id] == _vm.step.question.special_option.id
        )?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.$t(_vm.step.question.id, { context: _vm.step.question.special_option.id }))+"\n    ")],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }