<template>
  <div>
    <b-modal
      id="error-modal"
      :title="this.$t('error.title')"
      ok-only
      :ok-title="this.$t('error.buttonClose')"
      centered
    >
      <p>{{ message }}</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalError",
  props: ["message"],
};
</script>
