<template>
  <div></div>
</template>

<script>
export default {
  name: "DirectOrder",
  props: ["mixture", "name"],
  created: function () {
    if (this.$route.query.zp) {
      this.setCookie("znc_p", this.$route.query.zp, 30, true);
    }
    this.$router.replace({
      name: "ProductView",
      params: {
        product_num: this.mixture,
        firstname: this.name ? this.name : null,
      },
    });
  },
  methods: {
    setCookie: function (
      name,
      value,
      duration_days = 365,
      domain_only = false
    ) {
      let date = new Date();
      date.setTime(date.getTime() + duration_days * 24 * 60 * 60 * 1000);
      let domain = "";
      if (domain_only) {
        const hostname = window.location.hostname.split(".");
        domain = "; domain=" + hostname.slice(hostname.length - 2).join(".");
      }
      document.cookie =
        name +
        "=" +
        encodeURIComponent(value) +
        "; expires=" +
        date.toGMTString() +
        "; samesite=none; secure=true" +
        domain;
    },
  },
};
</script>
