<template>
  <b-modal
    id="modal-quantityselection"
    :title="
      quantityMode == 'quantity-upgrade' ? $t('titleUpgrade') : $t('title')
    "
    title-class="f-h1"
    :hide-header-close="quantityMode == 'quantity-upgrade' ? false : true"
    hide-footer
    size="xl"
  >
    <div class="modal-subtitle1 f-h3">
      {{ $t("subtitle1") }}
    </div>
    <div class="modal-subtitle2 f-p3">
      {{ $t("subtitle2") }}
    </div>
    <b-container class="quantityselection" fluid md="*">
      <b-row align-h="center">
        <b-col
          cols="12"
          xl="3"
          class="quantity-selector"
          v-if="quantityMode != 'quantity-upgrade'"
        >
          <b-button
            @click="$emit('checkout_spray', 1)"
            href="#"
            variant="outline-primary"
          >
            <div class="quantity-caption">{{ $t("spray", { count: 1 }) }}</div>
            <b-row :no-gutters="true">
              <b-col cols="6" xl="12" class="spray-col">
                <div class="spray-wrapper">
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray1-1"
                  ></b-img>
                </div>
              </b-col>
              <b-col cols="6" xl="12" align-self="end">
                <b-row :no-gutters="true" class="price-discount-wrapper">
                  <b-col cols="12" xl="4"></b-col>
                  <b-col cols="12" xl="8" class="price-wrapper">
                    <b-row :no-gutters="true">
                      <b-col cols="12" xl="6" class="price-old"></b-col>
                      <b-col cols="12" xl="6" class="price-new">
                        {{ formatCurrency(price) }}
                      </b-col>
                    </b-row>
                    <b-row :no-gutters="true">
                      <b-col class="price-info">
                        <i18next :translation="$t('priceDesc')">
                          <template #lineBreak>
                            <br />
                          </template>
                        </i18next>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-button>
        </b-col>
        <b-col cols="12" xl="3" class="quantity-selector">
          <b-button
            @click="$emit('checkout_spray', 2)"
            href="#"
            variant="outline-primary"
          >
            <div class="advice-wrapper">
              <div class="advice">
                <i18next :translation="$t('advice', { context: '2' })">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </div>
            </div>
            <div class="quantity-caption">{{ $t("spray", { count: 2 }) }}</div>
            <b-row :no-gutters="true">
              <b-col cols="6" xl="12" class="spray-col">
                <div class="spray-wrapper">
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray2-2"
                  ></b-img>
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray2-1"
                  ></b-img>
                </div>
              </b-col>
              <b-col cols="6" xl="12" align-self="end">
                <b-row :no-gutters="true" class="price-discount-wrapper">
                  <b-col cols="12" xl="4">
                    <div class="discount">{{ $t("save", { count: 10 }) }}</div>
                  </b-col>
                  <b-col cols="12" xl="8" class="price-wrapper">
                    <b-row :no-gutters="true">
                      <b-col cols="12" xl="6" class="price-old">
                        {{ formatCurrency(price) }}
                      </b-col>
                      <b-col cols="12" xl="6" class="price-new">
                        {{ formatCurrency(price * 0.9) }}
                      </b-col>
                    </b-row>
                    <b-row :no-gutters="true">
                      <b-col class="price-info">
                        <i18next :translation="$t('priceDesc')">
                          <template #lineBreak>
                            <br />
                          </template>
                        </i18next>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-button>
        </b-col>
        <b-col cols="12" xl="3" class="quantity-selector">
          <b-button
            @click="$emit('checkout_spray', 3)"
            href="#"
            variant="outline-primary"
          >
            <div class="quantity-caption">{{ $t("spray", { count: 3 }) }}</div>
            <b-row :no-gutters="true">
              <b-col cols="6" xl="12" class="spray-col">
                <div class="spray-wrapper">
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray3-3"
                  ></b-img>
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray3-2"
                  ></b-img>
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray3-1"
                  ></b-img>
                </div>
              </b-col>
              <b-col cols="6" xl="12" align-self="end">
                <b-row :no-gutters="true" class="price-discount-wrapper">
                  <b-col cols="12" xl="4">
                    <div class="discount">{{ $t("save", { count: 15 }) }}</div>
                  </b-col>
                  <b-col cols="12" xl="8" class="price-wrapper">
                    <b-row :no-gutters="true">
                      <b-col cols="12" xl="6" class="price-old">
                        {{ formatCurrency(price) }}
                      </b-col>
                      <b-col cols="12" xl="6" class="price-new">
                        {{ formatCurrency(price * 0.85) }}
                      </b-col>
                    </b-row>
                    <b-row :no-gutters="true">
                      <b-col class="price-info">
                        <i18next :translation="$t('priceDesc')">
                          <template #lineBreak>
                            <br />
                          </template>
                        </i18next>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-button>
        </b-col>
        <b-col cols="12" class="quantity-selector subscription-mobile">
          <b-button
            @click="$emit('checkout_subscription')"
            href="#"
            variant="outline-primary"
          >
            <div class="advice-wrapper">
              <div class="advice">
                <i18next :translation="$t('advice', { context: 'subscribe' })">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </div>
            </div>
            <div class="quantity-caption">{{ $t("subscribeCaption_m") }}</div>
            <div class="description">
              {{ $t("subscribeDesc") }}
            </div>
            <b-row :no-gutters="true">
              <b-col cols="12" align-self="end" class="price-discount-wrapper">
                <b-row :no-gutters="true" class="price-wrapper">
                  <b-col cols="12" class="price-old">
                    {{ formatCurrency(price) }}
                  </b-col>
                  <b-col cols="12" class="price-new">
                    {{ formatCurrency(price * 0.8) }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row :no-gutters="true">
              <b-col>
                <div class="discount">{{ $t("save", { count: 20 }) }}</div>
              </b-col>
              <b-col class="price-info">
                <i18next :translation="$t('priceDesc')">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </b-col>
            </b-row>
          </b-button>
        </b-col>
        <b-col cols="12" xl="3" class="subscription-desktop quantity-selector">
          <b-button
            @click="$emit('checkout_subscription')"
            href="#"
            variant="outline-primary"
          >
            <div class="advice-wrapper">
              <div class="advice">
                <i18next :translation="$t('advice', { context: 'subscribe' })">
                  <template #lineBreak>
                    <br />
                  </template>
                </i18next>
              </div>
            </div>
            <div class="quantity-caption">{{ $t("subscribeCaption_d") }}</div>
            <div class="description">
              {{ $t("subscribeDesc") }}
            </div>
            <b-row :no-gutters="true">
              <b-col cols="6" xl="12" class="spray-col">
                <div class="spray-wrapper">
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray3-3"
                  ></b-img>
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray3-2"
                  ></b-img>
                  <b-img
                    src="@/assets/spray.png"
                    class="spray spray3-1"
                  ></b-img>
                </div>
              </b-col>
              <b-col cols="6" xl="12" align-self="end">
                <b-row :no-gutters="true" class="price-discount-wrapper">
                  <b-col cols="12" xl="4">
                    <div class="discount">{{ $t("save", { count: 20 }) }}</div>
                  </b-col>
                  <b-col cols="12" xl="8" class="price-wrapper">
                    <b-row :no-gutters="true">
                      <b-col cols="12" xl="6" class="price-old">
                        {{ formatCurrency(price) }}
                      </b-col>
                      <b-col cols="12" xl="6" class="price-new">
                        {{ formatCurrency(price * 0.8) }}
                      </b-col>
                    </b-row>
                    <b-row :no-gutters="true">
                      <b-col class="price-info">
                        <i18next :translation="$t('priceDesc')">
                          <template #lineBreak>
                            <br />
                          </template>
                        </i18next>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: "ModalQuantitySelection",
  props: ["price", "quantityMode"],
  i18nOptions: { keyPrefix: "quantitySelection" },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "modal-quantityselection") {
        this.$EventBus.$emit("hideChatWidget", true);
      }
    });
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "modal-quantityselection") {
        this.$EventBus.$emit("showChatWidget", true);
      }
    });
  },
};
</script>

<style>
#modal-quantityselection .modal-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
}
#modal-quantityselection .modal-subtitle1 {
  text-align: center;
  font-weight: bold;
}
#modal-quantityselection .modal-subtitle2 {
  text-align: center;
}

#modal-quantityselection .quantity-selector .container {
  padding: 0px;
}

#modal-quantityselection .quantity-selector a {
  position: relative;
  width: 100%;
  padding: 0;
  margin-top: 1rem;
}
#modal-quantityselection .quantity-caption {
  padding-right: 0.7rem;
  padding-top: 0.2rem;
  text-align: right;
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--zimply-black);
}
#modal-quantityselection .description {
  font-size: 0.8125rem;
  color: var(--zimply-black);
  padding-right: 0.7rem;
  text-align: right;
}
#modal-quantityselection .subscription-desktop .description {
  padding-left: 1.7rem;
}
#modal-quantityselection .subscription-mobile .description {
  padding-left: 3.7rem;
}

#modal-quantityselection .spray-col {
  border-bottom: solid 1px var(--zimply-black);
}
#modal-quantityselection .spray-wrapper {
  position: relative;
  margin: auto;
  height: 12rem;
  width: 0px;
}
#modal-quantityselection .subscription-desktop .spray-wrapper {
  height: 9.52rem;
}
#modal-quantityselection .spray {
  position: absolute;
  height: 10rem;
  margin-bottom: 1rem;
}
#modal-quantityselection .subscription-desktop .spray {
  height: 7.52rem;
}
#modal-quantityselection .spray1-1 {
  left: -2.5rem;
  bottom: 0rem;
}
#modal-quantityselection .spray2-1 {
  left: -3.7rem;
  bottom: 0rem;
}
#modal-quantityselection .spray2-2 {
  left: -1.3rem;
  bottom: 0.4rem;
}
#modal-quantityselection .spray3-1 {
  left: -4.9rem;
  bottom: 0rem;
}
#modal-quantityselection .spray3-2 {
  left: -2.5rem;
  bottom: 0.4rem;
}
#modal-quantityselection .subscription-desktop .spray3-2 {
  opacity: 0.5;
}
#modal-quantityselection .spray3-3 {
  left: -0.1rem;
  bottom: 0.8rem;
}
#modal-quantityselection .subscription-desktop .spray3-3 {
  opacity: 0.25;
}

#modal-quantityselection .price-discount-wrapper {
  font-size: 1.1rem;
  font-weight: 600;
}
#modal-quantityselection .discount {
  background-color: var(--zimply-light-green);
  color: var(--zimply-black);
  height: 100%;
  border-radius: 0 10px 10px 10px;
}
#modal-quantityselection .price-old {
  text-decoration: line-through;
  display: inline-block;
  color: var(--red);
}
#modal-quantityselection .price-new {
  color: var(--zimply-black);
}
#modal-quantityselection .price-info {
  font-size: 0.6rem;
  font-weight: normal;
  color: var(--zimply-black);
  text-align: right;
  padding-right: 0.4rem;
}

#modal-quantityselection .subscription-mobile {
  display: none;
}
#modal-quantityselection .subscription-mobile .discount {
  padding: 0.2rem 1rem 0.2rem 1rem;
  float: left;
  text-align: right;
  border-radius: 0px 10px 10px 0px;
}
#modal-quantityselection .subscription-mobile .discount {
  border-radius: 0px 10px 10px 10px;
}

#modal-quantityselection .button1-wrapper {
  text-align: center;
  padding-top: 1rem;
}

#modal-quantityselection .advice-wrapper {
  width: 115px;
  height: 115px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;
}
#modal-quantityselection .advice {
  transform: rotate(-45deg);
  position: relative;
  padding: 4px 0;
  left: -37px;
  top: 19px;
  width: 150px;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  background-color: var(--zimply-light-green);
  color: var(--zimply-black);
  box-shadow: 0px 0px 3px var(--zimply-light-green-t80);
}
#modal-quantityselection .subscription-desktop .advice,
#modal-quantityselection .subscription-mobile .advice {
  background-color: var(--red);
}
@media (min-width: 1200px) {
  #modal-quantityselection .modal-xl {
    max-width: 1054px;
  }
}
@media only screen and (max-width: 1200px) {
  #modal-quantityselection .modal-xl {
    max-width: 500px;
  }
  #modal-quantityselection .spray-wrapper {
    height: 8rem;
  }
  #modal-quantityselection .spray {
    height: 8rem;
    margin-bottom: 0;
  }
  #modal-quantityselection .spray1-1 {
    left: calc(-2.5rem / 10 * 8);
  }
  #modal-quantityselection .spray2-1 {
    left: calc(-3.7rem / 10 * 8);
  }
  #modal-quantityselection .spray2-2 {
    left: calc(-1.3rem / 10 * 8);
    bottom: calc(0.4rem / 10 * 8);
  }
  #modal-quantityselection .spray3-1 {
    left: calc(-4.9rem / 10 * 8);
  }
  #modal-quantityselection .spray3-2 {
    left: calc(-2.5rem / 10 * 8);
    bottom: calc(0.4rem / 10 * 8);
  }
  #modal-quantityselection .spray3-3 {
    left: calc(-0.1rem / 10 * 8);
    bottom: calc(0.8rem / 10 * 8);
  }
  #modal-quantityselection .spray-col {
    border: unset;
  }

  #modal-quantityselection .discount {
    padding: 0.2rem 1rem 0.2rem 1rem;
    float: right;
    text-align: right;
    border-radius: 10px 10px 10px 10px;
  }

  #modal-quantityselection .price-old,
  #modal-quantityselection .price-new {
    padding-top: 0.1rem;
    padding-right: 1rem;
    text-align: right;
  }
  #modal-quantityselection .price-info {
    padding-right: 1rem;
    padding-bottom: 0.5rem;
  }

  #modal-quantityselection .subscription-mobile {
    display: unset;
  }
  #modal-quantityselection .subscription-desktop {
    display: none;
  }
}

#modal-quantityselection .advice:before,
#modal-quantityselection .advice:after {
  content: "";
  position: absolute;
  bottom: -3px;
  border-top: 3px solid var(--zimply-light-green);
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}
#modal-quantityselection .advice:before {
  left: 0;
}
#modal-quantityselection .advice:after {
  right: 0;
}

#modal-quantityselection .btn-outline-primary {
  border-color: var(--zimply-black);
}
#modal-quantityselection .subscription-desktop .btn-outline-primary,
#modal-quantityselection .subscription-mobile .btn-outline-primary {
  background-color: var(--zimply-beige);
}
#modal-quantityselection .btn-outline-primary:hover,
#modal-quantityselection .btn-outline-primary.focus,
#modal-quantityselection .btn-outline-primary:focus,
#modal-quantityselection .btn-outline-primary.active,
#modal-quantityselection .btn-outline-primary:active {
  background-color: var(--gray);
}
</style>
