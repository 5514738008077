import Vue from "vue";

Vue.mixin({
  methods: {
    _loadPayPal: function (subscription = false) {
      let appendix = "";
      if (subscription) {
        appendix = "&vault=true&intent=subscription";
      }
      return new Promise((resolve) => {
        let url =
          "https://www.paypal.com/sdk/js?client-id=" +
          this.$paypal_cid +
          "&currency=EUR&disable-funding=card,credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo" +
          appendix;
        let pp = document.createElement("script");
        pp.src = url;
        pp.onload = () => resolve(true);
        pp.onerror = () => resolve(false);
        document.head.appendChild(pp);
      });
    },
    _finishCheckout: function (checkoutData) {
      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checkoutData),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              this.$EventBus.$emit("gtag", {
                event: "konfigurator-checkout",
                schritt: "bestellung",
                teilschritt: checkoutData.paymentInfo.method,
                bestellung_nummer: result.orderId,
                bestellung_gesamtbetrag_brutto: checkoutData.cartSummary.sum,
                bestellung_gesamtbetrag_netto: checkoutData.cartSummary.netto,
                bestellung_gutscheincode: checkoutData.voucher
                  ? checkoutData.voucher.code
                  : null,
              });
              this.$EventBus.$emit("gtag_ecom", {
                event: "checkout",
                ecommerce: {
                  currencyCode: "EUR",
                  checkout: {
                    actionField: {
                      step: 2,
                      option: checkoutData.paymentInfo.method,
                    },
                    products: checkoutData.cart,
                  },
                },
                cart: checkoutData.cart,
              });
              this.$EventBus.$emit("gtag_ecom", {
                event: "purchase",
                ecommerce: {
                  currencyCode: "EUR",
                  purchase: {
                    actionField: {
                      id: result.orderId,
                      revenue: checkoutData.cartSummary.sum,
                      tax: checkoutData.cartSummary.tax,
                      discount: this.round(
                        checkoutData.cartSummary.voucher * -1
                      ),
                    },
                    products: checkoutData.cart,
                  },
                },
                cart: checkoutData.cart,
              });
              this.$EventBus.$emit(
                "track_goal",
                4,
                checkoutData.cartSummary.sum
              );

              this.$order_info.orderId = result.orderId;
              this.$order_info.email =
                checkoutData.billing && checkoutData.billing.email
                  ? checkoutData.billing.email
                  : this.$user.email;
              this.$order_info.sum = checkoutData.cartSummary.sum;
              this.$order_info.estDeliveryDate = result.estDeliveryDate;

              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    getQuantityDiscount(quantity) {
      let discountp = 0;
      if (quantity >= 3) {
        discountp = 15;
      } else if (quantity == 2) {
        discountp = 10;
      }
      return discountp;
    },
    optionsSalutation: function () {
      return [
        {
          value: null,
          text: this.$t("checkout.salutation", { context: "Label" }),
          disabled: true,
        },
        {
          value: "Herr",
          text: this.$t("checkout.salutation", { context: "Mr" }),
        },
        {
          value: "Frau",
          text: this.$t("checkout.salutation", { context: "Ms" }),
        },
      ];
    },
    optionsCountry: function () {
      let countries = [
        {
          value: null,
          text: this.$t("checkout.country", { context: "Label" }),
          disabled: true,
          rank: 0,
        },
        {
          value: { name: "Deutschland", code: "DE" },
          text: this.$t("checkout.country", { context: "DE" }),
          rank: 1,
        },
        {
          value: { name: "Österreich", code: "AT" },
          text: this.$t("checkout.country", { context: "AT" }),
          rank: 2,
        },
        {
          value: { name: "Belgien", code: "BE" },
          text: this.$t("checkout.country", { context: "BE" }),
          rank: 5,
        },
        {
          value: { name: "Bulgarien", code: "BG" },
          text: this.$t("checkout.country", { context: "BG" }),
          rank: 5,
        },
        {
          value: { name: "Dänemark", code: "DK" },
          text: this.$t("checkout.country", { context: "DK" }),
          rank: 5,
        },
        {
          value: { name: "Estland", code: "EE" },
          text: this.$t("checkout.country", { context: "EE" }),
          rank: 5,
        },
        {
          value: { name: "Finnland", code: "FI" },
          text: this.$t("checkout.country", { context: "FI" }),
          rank: 5,
        },
        {
          value: { name: "Frankreich", code: "FR" },
          text: this.$t("checkout.country", { context: "FR" }),
          rank: 5,
        },
        {
          value: { name: "Griechenland", code: "GR" },
          text: this.$t("checkout.country", { context: "GR" }),
          rank: 5,
        },
        {
          value: { name: "Irland", code: "IE" },
          text: this.$t("checkout.country", { context: "IE" }),
          rank: 5,
        },
        {
          value: { name: "Italien", code: "IT" },
          text: this.$t("checkout.country", { context: "IT" }),
          rank: 5,
        },
        {
          value: { name: "Kroatien", code: "HR" },
          text: this.$t("checkout.country", { context: "HR" }),
          rank: 5,
        },
        {
          value: { name: "Lettland", code: "LV" },
          text: this.$t("checkout.country", { context: "LV" }),
          rank: 5,
        },
        {
          value: { name: "Litauen", code: "LT" },
          text: this.$t("checkout.country", { context: "LT" }),
          rank: 5,
        },
        {
          value: { name: "Luxemburg", code: "LU" },
          text: this.$t("checkout.country", { context: "LU" }),
          rank: 5,
        },
        {
          value: { name: "Malta", code: "MT" },
          text: this.$t("checkout.country", { context: "MT" }),
          rank: 5,
        },
        {
          value: { name: "Niederlande", code: "NL" },
          text: this.$t("checkout.country", { context: "NL" }),
          rank: 5,
        },
        {
          value: { name: "Polen", code: "PL" },
          text: this.$t("checkout.country", { context: "PL" }),
          rank: 5,
        },
        {
          value: { name: "Portugal", code: "PT" },
          text: this.$t("checkout.country", { context: "PT" }),
          rank: 5,
        },
        {
          value: { name: "Rumänien", code: "RO" },
          text: this.$t("checkout.country", { context: "RO" }),
          rank: 5,
        },
        {
          value: { name: "Schweden", code: "SE" },
          text: this.$t("checkout.country", { context: "SE" }),
          rank: 5,
        },
        {
          value: { name: "Slowakei", code: "SK" },
          text: this.$t("checkout.country", { context: "SK" }),
          rank: 5,
        },
        {
          value: { name: "Slowenien", code: "SI" },
          text: this.$t("checkout.country", { context: "SI" }),
          rank: 5,
        },
        {
          value: { name: "Spanien", code: "ES" },
          text: this.$t("checkout.country", { context: "ES" }),
          rank: 5,
        },
        {
          value: { name: "Tschechien", code: "CZ" },
          text: this.$t("checkout.country", { context: "CZ" }),
          rank: 5,
        },
        {
          value: { name: "Ungarn", code: "HU" },
          text: this.$t("checkout.country", { context: "HU" }),
          rank: 5,
        },
        {
          value: { name: "Zypern", code: "CY" },
          text: this.$t("checkout.country", { context: "CY" }),
          rank: 5,
        },
      ];
      countries.sort((a, b) => {
        if (a.rank - b.rank != 0) {
          return a.rank - b.rank;
        }
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      return countries;
    },
  },
});
