<template>
  <QuestionBase :step="step">
    <b-row class="text number-input-wrapper text-center m-0">
      <b-col col align-self-center>
        <b-form-input
          id="input_question_input"
          size="lg"
          v-model.trim="answer"
          class="number-input f-p1"
          :type="step.question.format"
          :number="step.question.format == 'number' ? true : false"
          :min="step.question.validation.min"
          :max="step.question.validation.max"
          @keydown.enter="validateAndSubmit()"
          :state="valid"
          :placeholder="$t(step.question.id, { context: 'placeholder' })"
        ></b-form-input>
        <span class="number-input-unit" v-if="step.question.unit">{{
          step.question.unit
        }}</span>
      </b-col>
    </b-row>

    <b-row class="justify-content-end m-0 mobile-justify-center">
      <b-button
        href="#"
        variant="green"
        size="lg"
        @click="validateAndSubmit()"
        class="col-12 col-md-2 mw-content"
      >
        <b-spinner v-if="running" small></b-spinner>
        {{ $t("buttonNext") }}
      </b-button>
    </b-row>
  </QuestionBase>
</template>

<script>
import QuestionBase from "@/components/QuestionBase.vue";

export default {
  name: "QuestionInput",
  components: {
    QuestionBase,
  },
  props: ["step", "answers", "running"],
  i18nOptions: { keyPrefix: "question" },
  data: function () {
    return {
      answer: this.answers[this.step.question.id],
      valid: null,
    };
  },
  methods: {
    validateAndSubmit: function () {
      this.valid = null;

      if (
        this.answer == undefined ||
        (typeof this.answer === "string" && this.answer.trim() == "")
      ) {
        this.valid = false;
        return;
      }
      if (this.step.question.validation) {
        if (
          this.step.question.validation.min &&
          this.answer < this.step.question.validation.min
        ) {
          this.valid = false;
          return;
        }
        if (
          this.step.question.validation.max &&
          this.answer > this.step.question.validation.max
        ) {
          this.valid = false;
          return;
        }
        if (
          this.step.question.validation.excludes &&
          this.step.question.validation.excludes.indexOf(this.answer.trim()) >
            -1
        ) {
          this.valid = false;
          return;
        }
        if (typeof this.answer === "string" && this.answer.indexOf("/") > -1) {
          this.valid = false;
          return;
        }
      }

      if (this.valid == null) {
        this.$emit("answer_and_next", this.answer);
      }
    },
  },
  watch: {
    step: function () {
      document.getElementById("input_question_input").focus();
    },
  },
};
</script>
